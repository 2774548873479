import React, { useEffect, useState } from 'react'

import { getFormattedDate } from '../../utils/dates'
import RawLoader from '../shared/Loader/RawLoader'
import { DELIVERY_TYPES, PACKAGE_TYPES } from '../../constants/sales'
import { Table, TableRow } from '../shared/Table/Table'

import ManifestBar from './ManifestBar/ManifestBar'

const ManifestDetailsTable = ({
  autocompleteManifest,
  className,
  manifest,
  shippings,
  isLoading,
  setRemovedShippings,
  setAddedShippings,
  courier,
  dynamicId,
  setConfirmOpen,
  setSelectedShippingCount,
  record
}) => {
  const [checkedShippings, setCheckedShippings] = useState({})
  const [isSelectAll, setIsSelectAll] = useState(autocompleteManifest)
  const [isChangeChecks, setIChangeChecks] = useState(false)

  const getCheckValue = (trackingNumber) => {
    if (checkedShippings[trackingNumber] === undefined) {
      if (autocompleteManifest && !isChangeChecks ) {
        checkedShippings[trackingNumber] = true
        return true
      }
      return false
    }
    return checkedShippings[trackingNumber]
  }

  const removedAll = () => {
    setRemovedShippings(shippings.map(item => item.packageId))
  }

  const addAll = () => {
    setAddedShippings(shippings.map(item => item.trackingNumber))
  }

  const handleRemovedShippings = (shipping) => {
    const checkValue = getCheckValue(shipping.trackingNumber)
    setCheckedShippings({
      ...checkedShippings,
      [shipping.trackingNumber]: !checkValue
    })
    if (checkValue) {
      if(!autocompleteManifest){
        setAddedShippings((previousShippings) =>
          previousShippings.filter((trackingNumber) => trackingNumber !== shipping.trackingNumber)
        )
      }
      setRemovedShippings((previousShippings) => [...previousShippings, shipping.packageId])
    } else {
      if(!autocompleteManifest){
        setAddedShippings((previousShippings) => [...previousShippings, shipping.trackingNumber])
      }
      setRemovedShippings((previousShippings) =>
        previousShippings.filter((packageId) => packageId !== shipping.packageId)
      )
    }
  }

  const handleSelectAll = () => {
    setIsSelectAll(!isSelectAll)
    if(isSelectAll){
      setIChangeChecks(true)
      setCheckedShippings({})
      if (autocompleteManifest) {
        removedAll()
      } else {setAddedShippings([])}
    } else {
      if (autocompleteManifest) {
        setRemovedShippings([])
      } else {addAll()}
      const checked = shippings.reduce((acc, shipping) => {
        acc[shipping.trackingNumber] = true
        return acc
      }, {})
      setCheckedShippings(checked)
    }
  }

  const headerItem = () => {
    if (manifest.packageType === PACKAGE_TYPES[0]) {
      return (manifest.open || (!autocompleteManifest && !record )) ? 'Envío' : 'Servicio'
    }
    return ''
  }

  const headers = [
    <div className="close-manifest-table-data-checkbox">
      {(manifest.open || (!autocompleteManifest && !record )) && (
        <input
          type="checkbox"
          checked={isSelectAll}
          onChange={handleSelectAll}
        />
      )}
    </div>,
    'ID',
    'O. Transporte',
    'Comuna',
    'Región',
    <div>
      {(manifest.open || !autocompleteManifest) && 'Cliente'}
    </div>,
    headerItem(),
    'Fecha'
  ]

  useEffect(() => {
    setCheckedShippings({})
    if(!autocompleteManifest){
      removedAll()
    }
    return () => setCheckedShippings({})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manifest])

  useEffect(() => {
    const checkedLength = Object.values(checkedShippings).filter(value => value === true).length
    const uniqueTrackingNumbers = shippings ?
      [...new Set(shippings.map(shipping => shipping.trackingNumber))] : []

    if (shippings && (checkedLength === uniqueTrackingNumbers.length)) {
      setIsSelectAll(true)
    } else {
      setIsSelectAll(false)
    }
  }, [checkedShippings, shippings])


  if (isLoading) {
    return (
      <div className={className}>
        <div className="no-data loader">
          <RawLoader />
        </div>
      </div>
    )
  }

  if (!shippings || shippings.length === 0) {
    return (
      <div className={`bg-white border-x border-b mx-10 pb-2 ${className}`}>
        <h3 className="no-data">No hay ordenes de venta actualmente</h3>
      </div>
    )
  }

  return (
    <>
      <Table
        maxHeight="max-h-[50vh]"
        headers={headers}
      >
        {shippings?.map((shipping) => (
          <TableRow
            key={shipping.trackingNumber}
            object={shipping}
            onClick={() => {}}
            items={ [
              <div className="close-manifest-table-data-checkbox">
                { !record && (
                  <input
                    type="checkbox"
                    checked={getCheckValue(shipping.trackingNumber)}
                    onChange={() => handleRemovedShippings(shipping)}
                  />
                )}
              </div>,
              <span>{shipping.package.orderId}</span>,
              <span>{shipping.trackingNumber}</span>,
              <span>{shipping.package.city}</span>,
              <span>{shipping.package.state}</span>,
              <span>
                {(manifest.open || !autocompleteManifest) && shipping.package.customerName}
              </span>,
              <div className={`${ shipping.package.deliveryType ? '' : 'hidden'}`}>
                {shipping.package.deliveryType &&
                DELIVERY_TYPES[shipping.package.deliveryType]}
              </div>,
              <span>{getFormattedDate(shipping.package.purchaseDate)}</span>
            ]}
          />
        ))}
      </Table>
      <div className="absolute bottom-4 left-10">
        <ManifestBar
          amount={Object.values(checkedShippings).filter(value => value === true).length}
          courier={courier}
          dynamicId={dynamicId}
          setConfirmOpen={setConfirmOpen}
          setSelectedShippingCount={setSelectedShippingCount}
        />
      </div>
    </>
  )
}

export default ManifestDetailsTable

import React from 'react'

import { PIXEL_ID_NAME, ACCESS_TOKEN_NAME } from '../../../constants/integrationWelcome'

const GA4PixelCredentialsStep = ({ step, inputValues, handleInputChange, error,
  hasCredentialsSave}) => {

  const getPlaceholder = (id) => {
    const placeholders = {
      4: 'G-0123456789',
      6: 'Ingresa el valor secreto asignado'
    }
    return placeholders[id] || ''
  }

  const getInputText = (key) => {
    const inputText = {
      pixelId: 'ID de medición',
      pixelAccessToken: 'Valor secreto'
    }
    return inputText[key] || ''
  }

  return (
    <div>
      {step.logo}
      <div className="pb-4 text-lg font-semibold text-ultra-dark-grey">
        {step.title}
      </div>
      {step.substeps.map((substep) => (
        <div>
          <div className="py-2">{substep.description}</div>
          {substep.content && (
            <div className="py-6">{substep.content}</div>
          )}
        </div>
      ))}
      {(step.id === 4 || step.id === 6) && (
        <div className="py-2">
          <div className="text-sm font-medium text-gray-700 mb-1">
            {getInputText(step.id === 4 ? PIXEL_ID_NAME : ACCESS_TOKEN_NAME)}
          </div>
          {hasCredentialsSave ? (
            <div className="bg-gray-100 p-2 rounded-md text-gray-800">
              {step.id === 4 ? inputValues.pixelId : inputValues.pixelAccessToken}
            </div>
          ) : (
            <div className="relative w-1/3">
              <input
                value={inputValues[step.id === 4 ? PIXEL_ID_NAME : ACCESS_TOKEN_NAME]}
                onChange={(e) =>
                  handleInputChange(
                    step.id === 4 ? PIXEL_ID_NAME : ACCESS_TOKEN_NAME,
                    e.target.value
                  )
                }
                placeholder={getPlaceholder(step.id)}
                className={`border p-2 rounded-md w-full pr-10 ${
                  error ? 'border-red' : ''
                }`}
              />
              {error && (
                <div className="absolute inset-y-0 right-2 flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="w-5 h-5"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="11"
                      fill="white"
                      stroke="red"
                      strokeWidth="2"
                    />
                    <line
                      x1="12"
                      y1="7"
                      x2="12"
                      y2="14"
                      stroke="red"
                      strokeWidth="2"
                    />
                    <circle cx="12" cy="17" r="1" fill="red" />
                  </svg>
                </div>
              )}
            </div>
          )}
          {error && <div className="text-red text-sm mt-2">{error}</div>}
          <div className="py-2">
            *Para evitar perder el <span className="font-bold">{getInputText(step.id === 4 ? PIXEL_ID_NAME : ACCESS_TOKEN_NAME)}</span>,{' '}
             te sugerimos guardarlo en otro sitio antes de continuar. Si algo sale mal,{' '}
             podrás ingresarlo nuevamente.
          </div>
        </div>
      )}
    </div>
  )
}

export default GA4PixelCredentialsStep

import React, { useEffect, useState } from 'react'

import { servicesTypesObject } from '../../../../../helpers/couriers'
import SelectWithTags from '../../RuleFields/SelectWithTags'
import Button from '../../../../shared/Button'

const NewRuleModalActions = ({
  openModal,
  closeModal,
  setCurrentAction,
  actionType,
  couriers,
  courierServices
}) => {
  const [price, setPrice] = useState('')
  const [checked, setChecked] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [courier, setCourier] = useState('')
  const [deliveryType, setDeliveryType] = useState({
    storePickup: true,
    homeDelivery: true,
    clickAndCollect: true
  })
  const [serviceType, setServiceType] = useState(servicesTypesObject(courierServices))

  const actions = {
    price: () => setCurrentAction(actionType, price),
    courier: () => setCurrentAction(actionType, courier),
    deliveryType: () => setCurrentAction(actionType, deliveryType),
    serviceType: () => setCurrentAction(actionType, serviceType)
  }

  const actionsOptions = {
    price: 'price',
    courier: 'courier',
    deliveryType: 'deliveryType',
    serviceType: 'serviceType'
  }

  const handleClick = () => {
    actions[actionType]()
    closeModal(false)
  }

  const handleCheck = () => {
    setChecked(!checked)
    if (!checked) {
      setDisabled(true)
      setPrice(0)
    } else {
      setDisabled(false)
    }
  }

  const handleDeliveryType = (delivery, value) => {
    setDeliveryType({ ...deliveryType, [delivery]: value })
  }

  const handleServiceType = (service, value) => {
    setServiceType({ ...serviceType, [service]: value })
  }

  useEffect(() => {
    setPrice('0')
    setCourier('blueexpress')
    setDeliveryType({
      storePickup: true,
      homeDelivery: true,
      clickAndCollect: true
    })
    setServiceType(servicesTypesObject(courierServices))
  }, [courierServices])

  useEffect(() => {
    if (!openModal) {
      setPrice('0')
      setCourier('blueexpress')
      setDeliveryType({
        storePickup: true,
        homeDelivery: true,
        clickAndCollect: true
      })
      setServiceType(servicesTypesObject(courierServices))
    }
  }, [courierServices, openModal])

  return (
    <div className="">
      {actionType === actionsOptions.price && (
        <div>
          <p className="text-sm mt-2">Tarifa de Envío (CLP)</p>
          <div className="">
            <input
              type="number"
              className="border rounded text-xs py-2 px-4 mt-2"
              min="0"
              placeholder="0"
              value={price}
              disabled={disabled}
              onChange={(event) => setPrice(event.target.value)}
            />
            <div className="text-xs mt-3">
              <input className="mr-1" type="checkbox" checked={checked} onChange={handleCheck} />
              Envío gratis
            </div>
          </div>
        </div>
      )}
      {actionType === actionsOptions.courier && (
        <div>
          <p className="text-sm mt-2">Asignar Courier</p>
          <select
            className="border rounded text-xs py-2 px-4 mt-2"
            value={courier}
            onChange={(e) => setCourier(e.target.value)}
          >
            <option hidden value="">
              Selecciona un courier
            </option>
            {couriers?.map((currentCourier) => (
              <option key={currentCourier.id} value={currentCourier.name}>
                {currentCourier.name}
              </option>
            ))}
          </select>
        </div>
      )}
      {actionType === actionsOptions.deliveryType && (
        <SelectWithTags
          options={Object.keys(deliveryType)}
          selected={deliveryType}
          handleChange={handleDeliveryType}
          title="Tipo de envío"
          placeholder="Selecciona el tipo de envío"
        />
      )}
      {actionType === actionsOptions.serviceType && (
        <SelectWithTags
          options={courierServices.filter(service => service !== 'distribution' && service !== 'distribution-return')}
          selected={serviceType}
          handleChange={handleServiceType}
          title="Tipo de servicio"
          placeholder="Selecciona el tipo de servicio"
        />
      )}
      <div className="mt-4 flex justify-center">
        <Button color="bg-normal-pinflag" onClick={handleClick}>
          Agregar
        </Button>
      </div>
    </div>
  )
}

export default NewRuleModalActions

import React, { useState, useContext, useCallback } from 'react'
import secureLocalStorage from 'react-secure-storage'

import { getStores } from '../../helpers/request/stores'
import RawLoader from '../../components/shared/Loader/RawLoader'
import LoadingError from '../../components/shared/LoadingError'
import { AuthContext } from '../../contexts/Store'
import NoAccess from '../../components/NoAccess'
import SectionHeader from '../../components/shared/SectionHeader'
import Button from '../../components/shared/Button'
import useFetch from '../../hooks/useFetchParams'
import { getStates } from '../../helpers/request/states'
import { STORE_ICONS } from '../../constants/icons'
import AddStoreModal from '../../components/Stores/StoresList/AddStoreModal'
import StoresTable from '../../components/Stores/StoresList/StoresTable'

const Stores = () => {
  const [stores, setStores] = useState([])
  const [companyId] = useState(secureLocalStorage.getItem('companiesIds'))
  const [states, setStates] = useState({})
  const [openCreateStoreModal, setOpenCreateStoreModal] = useState(false)
  const [searchResult, setSearchResult] = useState([])

  useFetch(getStates, setStates)

  const { hasAccess, loadingResources } = useContext(AuthContext)

  const setStoresData = useCallback((data) => {
    setStores(data)
    setSearchResult(data)
  }, [])

  const { isLoading, error } = useFetch(getStores, setStoresData, false)

  const handleCreateStore = () => {
    setOpenCreateStoreModal(true)
  }

  if (!hasAccess('stores')) {
    return (
      <div className="h-screen bg-light-grey">
        <NoAccess />
      </div>
    )
  }

  return (
    <div className="flex flex-col h-screen bg-light-grey relative">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-white/80 z-50">
          <RawLoader />
        </div>
      )}
      {error && (
        <div className="absolute inset-0 flex items-center justify-center bg-white/80 z-50">
          <LoadingError />
        </div>
      )}

      <div className="bg-light-grey py-4 px-8">
        <div className="mx-10 py-8">
          <SectionHeader
            title="Mis tiendas"
            rightChildren={
              <Button
                color="bg-normal-pinflag"
                onClick={handleCreateStore}
                className="text-white"
              >
                <div className="flex justify-center items-center">
                  <img src={STORE_ICONS.addStore} alt="upload" className="h-4 w-4 mx-2" />
                  Crear tienda
                </div>
              </Button>
            }
          />
        </div>
        {!isLoading && !error && (
          <StoresTable
            stores={stores}
            setStores={setStores}
            searchResult={searchResult}
            setSearchResult={setSearchResult}
            isLoading={isLoading || loadingResources}
            error={error}
          />
        )}
        <AddStoreModal
          open={openCreateStoreModal}
          setOpen={setOpenCreateStoreModal}
          companyId={companyId}
          states={states}
        />
      </div>
    </div>
  )
}

export default Stores

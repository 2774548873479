import React, { useEffect, useRef } from 'react'

import EditBox from '../Personalization/EditBox'

import PinflagGateway from './PinflagGateway'

const PaymentGatewayEditBox = ({
  personalization,
  configuration,
  onUpdate,
  onCancel,
  isUpdated,
  changes,
  setChanges
}) => {
  const editedActiveRef = useRef(personalization.payment?.active || false)

  const activeValue = isUpdated
    ? editedActiveRef.current
    : (personalization.payment?.active || false)

  useEffect(() => {
    if (onCancel) {
      editedActiveRef.current = personalization.payment?.active || false
    }
  }, [onCancel, personalization.payment?.active])

  useEffect(() => {
    if (!isUpdated) {
      editedActiveRef.current = personalization.payment?.active || false
    }
  }, [personalization.payment?.active, isUpdated])

  const handleToggleActivePinflagPaymentGateway = () => {
    const newState = !editedActiveRef.current
    editedActiveRef.current = newState

    const activeToggleChanges = {
      ...changes,
      personalization: {
        ...changes.personalization,
        payment: {
          ...(changes.personalization?.payment || {}),
          active: newState
        }
      }
    }

    setChanges(activeToggleChanges)
    onUpdate(activeToggleChanges)
  }

  return (
    <div>
      <EditBox
        title="Pasarelas de pago"
        width="w-2/3"
        description="Habilita los proveedores de pago que deseas utilizar en tu tienda"
      >
        <div className="flex gap-8">
          <PinflagGateway
            active={activeValue}
            handleToggleActive={handleToggleActivePinflagPaymentGateway}
            personalization={personalization}
            configuration={configuration}
            onUpdate={onUpdate}
            changes={changes}
            setChanges={setChanges}
          />
        </div>
      </EditBox>
    </div>
  )
}

export default PaymentGatewayEditBox

import statsApi from '../../axiosConfig/statsApi'

export const getPackagesStats = async ({ params, signal }) => {
  const url = params ? `/stats/packages${params}` : '/stats/packages'
  const response = await statsApi.get(url, { signal })

  return response.data
}

export const getSLAStats = async ({ params, signal }) => {
  const url = params ? `/stats/sla${params}` : '/stats/sla'
  const response = await statsApi.get(url, { signal })

  return response.data
}

export const getIncidentsStats = async ({ params, signal }) => {
  const url = params ? `/stats/incidents${params}` : '/stats/incidents'
  const response = await statsApi.get(url, { signal })

  return response.data
}

export const getPinmapStats = async ({ params, signal }) => {
  const url = params ? `/stats/checkout${params}` : '/stats/checkout'
  const response = await statsApi.get(url, { signal })

  return response.data
}

export const getPixelCredentials = async (config) => {
  const { params: companyId } = config
  const response = await statsApi.get(`/pixel/company-credentials/${companyId}`)

  return response.data
}

export const updatePixelCredentials = async (body) =>
  statsApi.put('/pixel/company-credentials/', body)

export const updatePixelEvent = async (body) =>
  statsApi.put('/pixel/events-company-credentials/', body)

import React from 'react'

import Button from '../components/shared/Button'

import { ASSETS_URL } from './others'

export const CONFIGURATION_STEPS_UP = ['Empecemos', 'Ingreso', 'Conexión']
export const CONFIGURATION_STEPS_DOWN = ['', '', '', '', '']
export const META_PIXEL_NAME = 'facebook'

export const META_PIXEL_CREDENTIALS_IMAGES = {
  logoMeta: `${ASSETS_URL}/pixelCredentials/Meta/logo-meta.svg`,
  meta: `${ASSETS_URL}/pixelCredentials/Meta/meta.svg`,
  step1: `${ASSETS_URL}/pixelCredentials/Meta/paso1-imagen-A-B-C.svg`,
  step2: `${ASSETS_URL}/pixelCredentials/Meta/paso2-imagen-A-B-C-D.svg`,
  step3A: `${ASSETS_URL}/pixelCredentials/Meta/paso3-imagen-A-B-C.svg`,
  step3B: `${ASSETS_URL}/pixelCredentials/Meta/paso3-imagen-D-E-F-G-H.svg`,
  step3C: `${ASSETS_URL}/pixelCredentials/Meta/paso3-imagen-I.svg`,
  step4: `${ASSETS_URL}/pixelCredentials/Meta/paso4-imagen-A-B-C.svg`,
  step5A: `${ASSETS_URL}/pixelCredentials/Meta/paso5-imagen-A-B.svg`,
  step5B: `${ASSETS_URL}/pixelCredentials/Meta/paso5-imagen-C.svg`,
  step5C: `${ASSETS_URL}/pixelCredentials/Meta/paso5-imagen-D.svg`
}

export const META_PIXEL_CREDENTIALS_STEPS = [
  {
    id: 0,
    logo: <div className="flex gap-4 mb-5">
            <img src={META_PIXEL_CREDENTIALS_IMAGES.logoMeta} alt="Meta Logo" className="w-50" />
          </div>,
    title: 'Integración Meta (Facebook)',
    substeps: [
      {
        description: (
        <>
          Te damos la bienvenida a la integración de Meta (Facebook)
          <br />
          A continuación, te guiaremos a través de los pasos para que puedas{' '}
          aprovechar al máximo esta integración.
          <br />
          <br />
          Para comenzar debes ingresar a Meta (Facebook).
          <br />
          <div className="mt-16 py-6 w-1/6">
            <Button
              color="bg-normal-pinflag"
              width="w-full"
              onClick={() =>
              window.open(
                  'https://developers.facebook.com/apps/'
              )
              }
            >
              Ir a Meta
            </Button>
          </div>
        </>
        )
      }
    ]
  },
  {
    id: 1,
    title: 'Ingreso',
    subtitle: 'Paso 1: Crea una Aplicación',
    substeps: [
      {
        description: (
        <>
          En estos pasos lo que harás será registrarte como desarrollador en <u><a href='https://developers.facebook.com/apps/' target="_blank" rel="noreferrer">
          <span className="font-bold">Meta for Developers</span></a></u> y crear una aplicación empresarial
          <br />
          <ul className="list-disc pl-14 space-y-2">
            <li>Ingresa en <span className="font-bold">&quot;Crear Aplicación&quot;</span>, luego en <span className="font-bold">
              Detalles de la Aplicación</span> escribe un nombre para la aplicación y deja{' '}
              un correo electrónico <span className="font-bold">(Imagen A)</span>.
            </li>
            <li>En <span className="font-bold">Casos de Uso</span> selecciona <span className="font-bold">
              &quot;Otro&quot; (Imagen B)</span>.
            </li>
            <li>Luego en <span className="font-bold">Tipo de Aplicación</span> selecciona <span className="font-bold">
              &quot;Business&quot; (Empresa) (Imagen C)</span>.
            </li>
            <li>Finalmente asocia la aplicación <span className="font-bold">(Portafolio Empresarial)</span>{' '}
            con los eventos de tu empresa, en <span className="font-bold">Detalles</span> selecciona la empresa{' '}
            a la cual se tienen conectados los eventos <span className="font-bold">(Imagen C y D)</span>.
            </li>
          </ul>
        </>
        ),
        content: <img src={META_PIXEL_CREDENTIALS_IMAGES.step1} alt="step1" className="w-[90%]" />
      }
    ]
  },
  {
    id: 2,
    title: 'Ingreso',
    subtitle: 'Paso 2: Crea un Usuario',
    substeps: [
      {
        description: (
        <>
          Ahora debes acceder a <u><a href='https://business.facebook.com/' target="_blank" rel="noreferrer"><span className="font-bold">Business Manager</span></a></u>
          <br />
          <ul className="list-disc pl-14 space-y-2">
            <li>En el <span className="font-bold">menú principal</span> dirígete a{' '}
            <span className="font-bold">&quot;Configuración&quot; (Imagen A)</span>.
            </li>
            <li>Luego, en <span className="font-bold">Usuarios</span> selecciona <span className="font-bold">
              &quot;Usuarios del sistema&quot; (Imagen B)</span>.
            </li>
            <li>Selecciona el botón <span className="font-bold">
              &quot;Añadir&quot; (Imagen C)</span>.
            </li>
            <li>Se desplegará un recuadro en donde debes ingresar <span className="font-bold">
            &quot;Nombre&quot; (System User Name) y &quot;Rol&quot; (System User Rol)</span>.{' '}
            Aquí puedes seleccionar <span className="font-bold">&quot;Employee&quot;</span> y finalmente{' '}
            da click en <span className="font-bold">&quot;Create System User&quot; (Imagen D)</span>.
            </li>
          </ul>
          <br />
          <div
            className="inline-block border-solid border-2 border-green rounded-md px-4 py-2"
          >
            <i>
              ¿Ya tienes un Usuario del sistema creado?{' '}
              Aprieta &quot;Continuar&quot; y avanzarás al paso 3 de esta integración.
            </i>
          </div>

        </>
        ),
        content: <img src={META_PIXEL_CREDENTIALS_IMAGES.step2} alt="step2" className="w-[90%]" />
      }
    ]
  },
  {
    id: 3,
    title: 'Conexión',
    subtitle: 'Paso 3: Obtén el ID (Access Token)',
    substeps: [
      {
        description: (
        <>
          En <span className="font-bold">Usarios del sistema</span> selecciona a tu <span className="font-bold">Usario</span>
          <br />
          <ul className="list-disc pl-14 space-y-2">
            <li>Luego da click en el <span className="font-bold">menú de los 3 puntos &bull;&bull;&bull;</span> y selecciona{' '}
            <span className="font-bold">&quot;Asignar activos&quot; (Imagen A y B)</span>.
            </li>
            <li>En el siguiente recuadro de <span className="font-bold">Seleccionar activos y asignar permisos</span>{' '}
            da click en <span className="font-bold">&quot;Aplicaciones&quot;</span> y selecciona la <span className="font-bold">
              aplicación creada</span>. Ya en asignar permisos activo el control total{' '}
              <span
                style={{
                  display: 'inline-block',
                  width: '40px',
                  height: '20px',
                  backgroundColor: '#cde7f9',
                  borderRadius: '20px',
                  border: '1px solid #deebf0',
                  position: 'relative',
                  verticalAlign: 'middle',
                  marginLeft: '5px'
                }}
              >
                <span
                  style={{
                    display: 'block',
                    width: '16px',
                    height: '16px',
                    backgroundColor: '#1f85ae',
                    borderRadius: '50%',
                    border: '1px solidrgb(28, 122, 159)',
                    position: 'absolute',
                    top: '50%',
                    right: '2px',
                    transform: 'translateY(-50%)'
                  }}
                />
              </span>{' '}
              (en <span className="font-bold">
                Administrar la aplicación</span>) y luego continúa dando click en el botón <span className="font-bold">&quot;
                Asignar Activos&quot; (Imagen C)</span>.
            </li>
          </ul>
        </>
        ),
        content: <img src={META_PIXEL_CREDENTIALS_IMAGES.step3A} alt="step3A" className="w-[90%]" />
      },
      {
        description: (
          <ul className="list-disc pl-14 space-y-2">
            <li>Nuevamente en <span className="font-bold">Usuarios del sistema</span>, da click en{' '}
            <span className="font-bold">&quot;Generar Identificador&quot; (Imagen D)</span>.
            </li>
            <li>Aparecerá un recuadro con la pregunta: <span className="font-bold">¿Para qué aplicación{' '}
              quieres generar un identificador?</span> debes seleccionar la <span className="font-bold">
                aplicación recién creada (Imagen E)</span>.
            </li>
            <li>Luego continua con <span className="font-bold">¿Cuándo quieres que caduque el identificador?</span>{' '}
            debes seleccionar <span className="font-bold">&quot;Nunca&quot; (Imagen F)</span>.
            </li>
            <li>En permisos para la aplicación debes <span className="font-bold">seleccionar 2: ads_management y business_management (Imagen G)</span>.
            </li>
            <li>Y luego dar click en <span className="font-bold">&quot;Generar Identificador&quot; (Imagen H)</span>.
            </li>
          </ul>
        ),
        content: <img src={META_PIXEL_CREDENTIALS_IMAGES.step3B} alt="step3B" className="w-[90%]" />
      },
      {
        description: (
          <ul className="list-disc pl-14 space-y-2">
            <li>Ya en el último recuadro en <span className="font-bold">&quot;Done&quot;</span> se indica{' '}
            que el <span className="font-bold">Identificador (Id de access token)</span> ya está creado{' '}
            <span className="font-bold">(Imagen I)</span>.
            </li>
          </ul>
        ),
        content: <img src={META_PIXEL_CREDENTIALS_IMAGES.step3C} alt="step3C" className="w-[80%]" />
      },
      {
        description: (
          <ul className="list-disc pl-14 space-y-2">
            <li>Ahora <span className="font-bold">copia el identificador</span> generado he{' '}
            ingrésalo en el siguiente recuadro:
            </li>
          </ul>
        )
      }
    ]
  },
  {
    id: 4,
    title: 'Conexión',
    subtitle: 'Paso 4: Asigna permisos',
    substeps: [
      {
        description: (
        <>
          Ahora iremos a buscar el <span className="font-bold">Conjunto de Datos</span> donde se reciben{' '}
          los eventos y luego asignaremos permisos <span className="font-bold">(asignar activos)</span>.
        </>
        )
      },
      {
        description: (
        <>
          Volveremos a <span className="font-bold">usuarios del sistema</span>, selecciona a tu{' '}
          <span className="font-bold">usuario</span>
          <ul className="list-disc pl-14 space-y-2">
            <li>Luego da click en el <span className="font-bold">menú de los 3 puntos &bull;&bull;&bull;</span> y selecciona{' '}
            <span className="font-bold">&quot;Asignar activos&quot; (Imagen A y B)</span>.
            </li>
            <li>En el siguiente recuadro de <span className="font-bold">Seleccionar activos y asignar permisos</span>{' '}
            ahora da click en <span className="font-bold">&quot;Conjunto de datos&quot;</span> y selecciona la <span className="font-bold">
              aplicación creada</span>. Luego en <span className="font-bold">Asignar permisos</span> activa el control total{' '}
              <span
                  style={{
                    display: 'inline-block',
                    width: '40px',
                    height: '20px',
                    backgroundColor: '#cde7f9',
                    borderRadius: '20px',
                    border: '1px solid #deebf0',
                    position: 'relative',
                    verticalAlign: 'middle',
                    marginLeft: '5px'
                  }}
                >
                  <span
                    style={{
                      display: 'block',
                      width: '16px',
                      height: '16px',
                      backgroundColor: '#1f85ae',
                      borderRadius: '50%',
                      border: '1px solidrgb(28, 122, 159)',
                      position: 'absolute',
                      top: '50%',
                      right: '2px',
                      transform: 'translateY(-50%)'
                    }}
                  />
                </span>{' '}
              (en <span className="font-bold">
                Administrar conjunto de datos de eventos</span>) y luego continúa dando click en el botón <span className="font-bold">&quot;
                Asignar Activos&quot; (Imagen C)</span>.
            </li>
          </ul>
        </>
        ),
        content: <img src={META_PIXEL_CREDENTIALS_IMAGES.step4} alt="step4" className="w-[90%]" />
      }
    ]
  },
  {
    id: 5,
    title: 'Ingreso',
    subtitle: 'Paso 5: Obtén el ID de orígenes de datos',
    substeps: [
      {
        description: (
        <>
          Ahora debes dirigirte a <u><a href='https://www.facebook.com/events_manager2/' target="_blank" rel="noreferrer">Resumen Administrador de Eventos de Meta</a></u>
          <br />
          <ul className="list-disc pl-14 space-y-2">
            <li>En el menú lateral debes <span className="font-bold">cambiar el usuario</span> y{' '}
            <span className="font-bold">seleccionar &quot;Portafolio empresarial&quot; (Imagen A y B)</span>.
            </li>
            <li>Luego en el costado izquierdo selecciona <span className="font-bold">&quot;Orígenes de datos&quot;</span>.
            </li>
          </ul>
        </>
        ),
        content: <img src={META_PIXEL_CREDENTIALS_IMAGES.step5A} alt="step5A" className="w-[90%]" />
      },
      {
        description: (
        <ul className="list-disc pl-14 space-y-2">
          <li>Luego en el costado izquierdo selecciona <span className="font-bold">&quot;Orígenes de datos&quot; (Imagen C)</span>.
          </li>
        </ul>
        ),
        content: <img src={META_PIXEL_CREDENTIALS_IMAGES.step5B} alt="step5B" className="w-[40%]" />
      },
      {
        description: (
        <ul className="list-disc pl-14 space-y-2">
          <li>Se desplegará en la pantalla la información del{' '}
            <span className="font-bold">ID (identificador de Origen de Datos) (Imagen D)</span>.
          </li>
        </ul>
        ),
        content: <img src={META_PIXEL_CREDENTIALS_IMAGES.step5C} alt="step5C" className="w-[50%]" />
      },
      {
        description: (
          <ul className="list-disc pl-14 space-y-2">
            <li><span className="font-bold">Copia el ID</span> generado he ingrésalo en el siguiente recuadro:
            </li>
          </ul>
        )
      }
    ]
  },
  {
    id: 6,
    title: '¡La conexión con la plataforma se ha realizado con éxito!',
    substeps: [
      {
        description: (
          <div className="mb-[35vh]">
            Has completado todos los pasos del proceso de integración con Meta (Facebook).
            <br />
            <br />
            Ahora puedes empezar a aprovechar los datos recopilados de manera eficiente para optimizar{' '}
            tus campañas y tomar decisiones informadas.
          </div>
        )
      }
    ]
  },
  {
    id: 7,
    title: 'Conexión',
    subtitle: 'Paso 3: Obtén el ID (Access Token)',
    substeps: [
      {
        description: (
        <>
          En <span className="font-bold">Usarios del sistema</span> selecciona a tu <span className="font-bold">Usario</span>
          <br />
          <ul className="list-disc pl-14 space-y-2">
              <li>Luego da click en el <span className="font-bold">menú de los 3 puntos &bull;&bull;&bull;</span> y selecciona{' '}
              <span className="font-bold">&quot;Asignar activos&quot; (Imagen A y B)</span>.
              </li>
              <li>En el siguiente recuadro de <span className="font-bold">Seleccionar activos y asignar permisos</span>{' '}
              da click en <span className="font-bold">&quot;Aplicaciones&quot;</span> y selecciona la <span className="font-bold">
                aplicación creada</span>. Ya en asignar permisos activo el control total{' '}
                <span
                  style={{
                    display: 'inline-block',
                    width: '40px',
                    height: '20px',
                    backgroundColor: '#cde7f9',
                    borderRadius: '20px',
                    border: '1px solid #deebf0',
                    position: 'relative',
                    verticalAlign: 'middle',
                    marginLeft: '5px'
                  }}
                >
                  <span
                    style={{
                      display: 'block',
                      width: '16px',
                      height: '16px',
                      backgroundColor: '#1f85ae',
                      borderRadius: '50%',
                      border: '1px solidrgb(28, 122, 159)',
                      position: 'absolute',
                      top: '50%',
                      right: '2px',
                      transform: 'translateY(-50%)'
                    }}
                  />
                </span>{' '}
                (en <span className="font-bold">
                  Administrar la aplicación</span>) y luego continúa dando click en el botón <span className="font-bold">&quot;
                  Asignar Activos&quot; (Imagen C)</span>.
              </li>
          </ul>
        </>
        ),
        content: <img src={META_PIXEL_CREDENTIALS_IMAGES.step3A} alt="step3A" className="w-[90%]" />
      },
      {
        description: (
          <ul className="list-disc pl-14 space-y-2">
            <li>Nuevamente en <span className="font-bold">Usuarios del sistema</span>, da click en{' '}
            <span className="font-bold">&quot;Generar Identificador&quot; (Imagen D)</span>.
            </li>
            <li>Aparecerá un recuadro con la pregunta: <span className="font-bold">¿Para qué aplicación{' '}
              quieres generar un identificador?</span> debes seleccionar la <span className="font-bold">
                aplicación recién creada (Imagen E)</span>.
            </li>
            <li>Luego continua con <span className="font-bold">¿Cuándo quieres que caduque el identificador?</span>{' '}
            debes seleccionar <span className="font-bold">&quot;Nunca&quot; (Imagen F)</span>.
            </li>
            <li>En permisos para la aplicación debes <span className="font-bold">seleccionar 2: ads_management y business_management (Imagen G)</span>.
            </li>
            <li>Y luego dar click en <span className="font-bold">&quot;Generar Identificador&quot; (Imagen H)</span>.
            </li>
          </ul>
        ),
        content: <img src={META_PIXEL_CREDENTIALS_IMAGES.step3B} alt="step3B" className="w-[90%]" />
      },
      {
        description: (
          <ul className="list-disc pl-14 space-y-2">
            <li>Ya en el último recuadro en <span className="font-bold">&quot;Done&quot;</span> se indica{' '}
            que el <span className="font-bold">Identificador (Id de access token)</span> ya está creado{' '}
            <span className="font-bold">(Imagen I)</span>.
            </li>
          </ul>
        ),
        content: <img src={META_PIXEL_CREDENTIALS_IMAGES.step3C} alt="stepC" className="w-[80%]" />
      },
      {
        description: (
          <ul className="list-disc pl-14 space-y-2">
            <li>Ahora <span className="font-bold">copia el Identificador</span> generado que deberás{' '}
              ingresar en el recuadro correspondiente en Configuraciones.
            </li>
          </ul>
        )
      }
    ]
  },
  {
    id: 8,
    title: 'Conexión',
    subtitle: 'Paso 5: Obtén el ID de orígenes de datos',
    substeps: [
      {
        description: (
        <>
          Ahora debes dirigirte a <u><a href='https://www.facebook.com/events_manager2/' target="_blank" rel="noreferrer">Resumen Administrador de Eventos de Meta</a></u>
          <br />
          <ul className="list-disc pl-14 space-y-2">
            <li>En el menú lateral debes <span className="font-bold">cambiar el usuario</span> y{' '}
            <span className="font-bold">seleccionar &quot;Portafolio empresarial&quot; (Imagen A y B)</span>.
            </li>
            <li>Luego en el costado izquierdo selecciona <span className="font-bold">&quot;Orígenes de datos&quot;</span>.
            </li>
          </ul>
        </>
        ),
        content: <img src={META_PIXEL_CREDENTIALS_IMAGES.step5A} alt="step5A" className="w-[90%]" />
      },
      {
        description: (
          <ul className="list-disc pl-14 space-y-2">
            <li>Luego en el costado izquierdo selecciona <span className="font-bold">&quot;Orígenes de datos&quot; (Imagen C)</span>.
          </li>
        </ul>
        ),
        content: <img src={META_PIXEL_CREDENTIALS_IMAGES.step5B} alt="step5B" className="w-[40%]" />
      },
      {
        description: (
          <ul className="list-disc pl-14 space-y-2">
            <li>Se desplegará en la pantalla la información del{' '}
              <span className="font-bold">ID (identificador de Origen de Datos) (Imagen D)</span>.
            </li>
          </ul>
        ),
        content: <img src={META_PIXEL_CREDENTIALS_IMAGES.step5C} alt="step5C" className="w-[50%]" />
      },
      {
        description: (
          <ul className="list-disc pl-14 space-y-2">
            <li><span className="font-bold">Copia el ID</span> generado que deberás{' '}
              ingresar en el recuadro correspondiente en Configuraciones.
            </li>
          </ul>
        )
      }
    ]
  }
]

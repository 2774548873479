import React from 'react'

const checkedColor = (disabled) => (disabled ? 'bg-gray-400 border-gray-400' : 'bg-teal-400 border-teal-400')

const TicketCheckBox = ({ checked, handleChange, text, disabled = false }) => (
  <div
    className={`
      flex items-center px-4 py-0 bg-white rounded-lg border border-gray-200
      ${disabled
        ? 'bg-gray-100 cursor-not-allowed'
        : 'hover:bg-gray-50 cursor-pointer group'} 
      shadow-sm h-10
    `}
    onClick={disabled ? undefined : handleChange}
    role="button"
    tabIndex={disabled ? -1 : 0}
  >
    <div className={`
      flex-shrink-0 w-5 h-5 border rounded
      ${checked
        ? checkedColor(disabled)
        : 'border-gray-300 bg-white'}
      flex items-center justify-center mr-3
    `}>
      {checked && (
        <svg
          className="w-3 h-3 text-white"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 3L4.5 8.5L2 6"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </div>
    <div className="flex items-center">
      <span className={`
        text-sm 
        ${disabled ? 'text-gray-400' : 'text-gray-600 group-hover:text-black'}
      `}>
        {text}
      </span>
    </div>
  </div>
)

export default TicketCheckBox

import React, { useState, useEffect } from 'react'

import Button from '../../../shared/Button'
import randomApi from '../../../../axiosConfig/randomApi'
import { WAREHOUSE_URL } from '../../../../constants/urls'
import EditBox from '../../../Settings/Personalization/EditBox'
import ToggleSwitch from '../../../shared/ToggleSwitch/ToggleSwitch.style'
import { COLORS } from '../../../../constants/styles'
import RawLoader from '../../../shared/Loader/RawLoader'
import LoadingError from '../../../shared/LoadingError'

const Distribution = ({ store }) => {
  const [activeDistribution, setActiveDistribution]
    = useState(store.DistributionCenterWarehouse?.active)

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [error, setError] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const warehouseId = store.id

  const handleSubmit = async () => {
    const body = {
      distributionCenter: {
        active: activeDistribution
      }
    }

    setIsLoadingSubmit(true)

    try {
      await randomApi().put(WAREHOUSE_URL(warehouseId), body)

      setIsUpdated(false)
    } catch {
      setError(true)
    } finally {
      setIsLoadingSubmit(false)
      setSubmitted(true)
    }
  }

  const handleCancel = () => {
    setActiveDistribution(store.DistributionCenterWarehouse.active)
  }

  useEffect(() => {
    setTimeout(() => {
      setSubmitted(false)
    }, 3000)
  }, [submitted])

  if (!store.DistributionCenterWarehouse) {
    return (
      <div className="flex justify-center mt-2">
        <div className="text-normal-pinflag">No se encontraron datos de distribución para esta tienda</div>
      </div>
    )
  }

  return (
    <>
      <div className="flex space-x-8">
        <div className="flex flex-col space-y-8">
          <EditBox
            title="Habilitado"
            description="Habilita la tienda para recibir pedidos de distribución"
          >
            <ToggleSwitch
              size="small"
              inactiveColor={COLORS.LIGHT_GREY}
              check={activeDistribution}
              setCheck={() => {
                setActiveDistribution(!activeDistribution)
                setIsUpdated(true)
              }}
            />
          </EditBox>
        </div>
      </div>
      {error && <LoadingError />}
      {isUpdated && !isLoadingSubmit && (
        <div className="flex gap-2 justify-center mt-2">
          <Button color="bg-normal-pinflag" onClick={handleSubmit} small>
            Guardar
          </Button>
          <Button color="bg-dark-grey" onClick={handleCancel} small>
            Cancelar
          </Button>
        </div>
      )}
      {isLoadingSubmit && <RawLoader />}
      {submitted && (
        <div className="flex justify-center mt-2">
          <div className="text-normal-pinflag">¡Guardado exitoso!</div>
        </div>
      )}
    </>
  )
}

export default Distribution

import React, { useRef, useEffect, useState } from 'react'

import EditBox from '../Personalization/EditBox'
import Button from '../../shared/Button'
import ToggleSwitch from '../../shared/ToggleSwitch/ToggleSwitch.style'
import ProviderCredentialsModal from '../ProviderCredentialsModal'
import { COLORS } from '../../../constants/styles'
import {
  PACKAGE_BOUGHT,
  PACKAGE_IN_TRANSIT,
  PACKAGE_ON_DELIVERY,
  PACKAGE_PICKED_UP,
  PACKAGE_PROCESSED,
  PACKAGE_RECEIVED,
  PACKAGE_STATUS_TRANSLATE,
  PACKAGE_STORED,
  PACKAGE_DELIVERED
} from '../../../constants/packageStatus'
import TicketCheckBox from '../../shared/TicketCheckBox'
import { checklistIcon, editIcon3, emissionIcon, passkeyIcon, providerIcon } from '../../../constants/icons'

const TaxDocumentsEditBox = ({
  personalization,
  configuration,
  onUpdate,
  onCancel,
  isUpdated,
  changes,
  setChanges
}) => {
  const editedProviderRef = useRef(configuration?.electronicTaxDocumentProvider?.provider || '')
  const editedActiveRef = useRef(personalization.taxDocumentEmission?.active || false)
  const editedInvoiceStatusRef = useRef(configuration?.electronicTaxDocumentProvider?.emissionStatuses?.invoice || '')
  const editedBillingStatusRef = useRef(configuration?.electronicTaxDocumentProvider?.emissionStatuses?.billing || '')
  const editedBillingFieldsRef = useRef(personalization.cmsActiveBillingFields || {
    ssn: false,
    businessLine: false,
    businessName: false,
    businessState: false,
    businessAddress: false,
    businessMunicipality: false
  })

  const [openCredentialsModal, setOpenCredentialsModal] = useState(false)

  useEffect(() => {
    if (onCancel && !isUpdated) {
      editedProviderRef.current = configuration?.electronicTaxDocumentProvider?.provider || ''
      editedActiveRef.current = personalization.taxDocumentEmission?.active || false
      editedInvoiceStatusRef.current = configuration?.electronicTaxDocumentProvider?.emissionStatuses?.invoice || ''
      editedBillingStatusRef.current = configuration?.electronicTaxDocumentProvider?.emissionStatuses?.billing || ''
      editedBillingFieldsRef.current = personalization.cmsActiveBillingFields || {
        ssn: false,
        businessLine: false,
        businessName: false,
        businessState: false,
        businessAddress: false,
        businessMunicipality: false
      }
    }
  }, [onCancel, configuration, personalization, isUpdated])

  const providers = ['bsale']

  const providerOptions = [
    { value: '', label: 'Seleccionar' },
    { value: 'bsale', label: 'BSale' },
    { value: 'none', label: 'Ninguno' }
  ]

  const isOptionAProvider = (option) => providers.includes(option)

  const packageStatuses = [
    { value: '', label: 'Seleccionar' },
    { value: PACKAGE_BOUGHT, label: PACKAGE_STATUS_TRANSLATE.bought },
    { value: PACKAGE_PROCESSED, label: PACKAGE_STATUS_TRANSLATE.processed },
    { value: PACKAGE_IN_TRANSIT, label: PACKAGE_STATUS_TRANSLATE.in_transit },
    { value: PACKAGE_ON_DELIVERY, label: PACKAGE_STATUS_TRANSLATE.on_delivery },
    { value: PACKAGE_RECEIVED, label: PACKAGE_STATUS_TRANSLATE.received },
    { value: PACKAGE_STORED, label: PACKAGE_STATUS_TRANSLATE.stored },
    { value: PACKAGE_PICKED_UP, label: PACKAGE_STATUS_TRANSLATE.picked_up },
    { value: PACKAGE_DELIVERED, label: PACKAGE_STATUS_TRANSLATE.delivered }
  ]

  const provider = isUpdated ? editedProviderRef.current : (configuration?.electronicTaxDocumentProvider?.provider || '')
  // eslint-disable-next-line
  const handleProviderChange = (e) => {
    const newValue = e.target.value
    const backendValue = newValue === 'none' ? null : newValue
    editedProviderRef.current = backendValue

    const providerChanges = {
      ...changes,
      personalization: {
        ...changes.personalization,
        ...personalization,
        taxDocumentEmission: { active: editedActiveRef.current }
      },
      configuration: {
        ...changes.configuration,
        ...configuration,
        electronicTaxDocumentProvider: {
          provider: backendValue,
          accessToken: configuration?.electronicTaxDocumentProvider?.accessToken,
          emissionStatuses: {
            invoice: editedInvoiceStatusRef.current,
            billing: editedBillingStatusRef.current
          }
        }
      }
    }
    setChanges(providerChanges)
    onUpdate(providerChanges)
  }

  const handleInvoiceStatusChange = (e) => {
    const newValue = e.target.value
    editedInvoiceStatusRef.current = newValue

    const statusChange = {
      ...changes,
      personalization: {
        ...personalization,
        ...changes.personalization,
        taxDocumentEmission: { active: editedActiveRef.current }
      },
      configuration: {
        ...configuration,
        ...changes.configuration,
        electronicTaxDocumentProvider: {
          provider: editedProviderRef.current,
          accessToken: configuration?.electronicTaxDocumentProvider?.accessToken,
          emissionStatuses: {
            invoice: newValue,
            billing: editedBillingStatusRef.current
          }
        }
      }
    }
    setChanges(statusChange)
    onUpdate(statusChange)
  }

  const handleBillingStatusChange = (e) => {
    const newValue = e.target.value
    editedBillingStatusRef.current = newValue

    const statusChange = {
      ...changes,
      personalization: {
        ...personalization,
        ...changes.personalization,
        taxDocumentEmission: { active: editedActiveRef.current }
      },
      configuration: {
        ...configuration,
        ...changes.configuration,
        electronicTaxDocumentProvider: {
          provider: editedProviderRef.current,
          accessToken: configuration?.electronicTaxDocumentProvider?.accessToken,
          emissionStatuses: {
            invoice: editedInvoiceStatusRef.current,
            billing: newValue
          }
        }
      }
    }
    setChanges(statusChange)
    onUpdate(statusChange)
  }

  const handleToggleActiveTaxDocumentEmission = () => {
    const newState = !editedActiveRef.current
    editedActiveRef.current = newState
    const activeChanges = {
      ...changes,
      personalization: {
        ...personalization,
        ...changes.personalization,
        taxDocumentEmission: { active: newState },
        cmsActiveBillingFields: editedBillingFieldsRef.current
      },
      configuration: {
        ...configuration,
        ...changes.configuration
      }
    }

    setChanges(activeChanges)
    onUpdate(activeChanges)
  }

  const handleBillingFieldChange = (field) => {
    const newFields = {
      ...editedBillingFieldsRef.current,
      [field]: !editedBillingFieldsRef.current[field]
    }

    editedBillingFieldsRef.current = newFields

    const billingFieldChanges = {
      ...changes,
      personalization: {
        ...personalization,
        ...changes.personalization,
        taxDocumentEmission: { active: editedActiveRef.current },
        cmsActiveBillingFields: newFields
      },
      configuration: {
        ...configuration,
        ...changes.configuration
      }
    }
    setChanges(billingFieldChanges)
    onUpdate(billingFieldChanges)
  }

  const hasProviderCredentials = (passedProvider) => {
    if (!passedProvider) return false
    const hasCredentials = configuration?.electronicTaxDocumentProvider?.provider === passedProvider
    return hasCredentials
  }

  const getButtonConfig = (passedProvider) => {
    const hasCredentials = hasProviderCredentials(passedProvider)
    return {
      text: hasCredentials ? 'Editar credenciales' : 'Ingresar credenciales',
      icon: hasCredentials ? editIcon3 : passkeyIcon,
      backgroundColor: hasCredentials ? COLORS.GREEN : null
    }
  }

  const isCheckboxesDisabled = provider && isOptionAProvider(provider)

  const handleCredentialsUpdate = (updates) => {
    const credentialsChanges = {
      ...changes,
      configuration: {
        ...configuration,
        ...updates.configuration,
        ...changes.configuration
      },
      personalization: {
        ...personalization,
        ...changes.personalization
      }
    }

    setChanges(credentialsChanges)
    onUpdate(credentialsChanges)
  }

  return (
    <div>
      <EditBox
        title={
          <div className="flex justify-between w-1/5">
            <div>Tributación</div>
            <ToggleSwitch
              size="ultra-small"
              inactiveColor={COLORS.LIGHT_GREY}
              check={editedActiveRef.current}
              setCheck={handleToggleActiveTaxDocumentEmission}
            />
          </div>
        }
        width="w-full"
        description="Habilita la emisión de documentos tributarios"
      >
        <div className="flex gap-4">
          <EditBox
            title={
              <div className="flex items-center gap-2">
                <img src={providerIcon} alt="pinflag" className="h-6 w-6" />
                <span>Proveedor</span>
              </div>
            }
            width="w-1/3"
            description="Elige qué empresa gestionará la emisión de documentos tributarios"
            className="p-6"
          >
            <div className="flex flex-col gap-4">
              <div className="text-sm text-gray-600">Proveedor</div>
              <select
                value={provider || ''}
                onChange={handleProviderChange}
                className="w-full border border-gray-300 rounded-md p-2 text-sm"
              >
                {providerOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>

              {(provider && isOptionAProvider(provider)) && (
                <Button
                  submit
                  color={getButtonConfig(provider).backgroundColor === COLORS.GREEN ? 'bg-normal-pinflag' : 'bg-light-pinflag'}
                  textColor={getButtonConfig(provider).backgroundColor === COLORS.GREEN ? 'text-white' : 'text-normal-pinflag'}
                  border={getButtonConfig(provider).backgroundColor === COLORS.GREEN ? 'border border-green' : 'border border-normal-pinflag'}
                  width="w-full"
                  className="mt-4"
                  onClick={() => setOpenCredentialsModal(true)}
                >
                  <div className="flex items-center justify-center gap-2">
                    <img
                      src={getButtonConfig(provider).icon}
                      alt="pinflag"
                      className="h-5 w-5"
                    />
                    <div>{getButtonConfig(provider).text}</div>
                  </div>
                </Button>)
              }
            </div>
            {openCredentialsModal && (
              <ProviderCredentialsModal
                provider="bsale"
                configuration={configuration}
                isOpen={openCredentialsModal}
                closeModal={() => setOpenCredentialsModal(false)}
                onSuccess={() => setOpenCredentialsModal(false)}
                onUpdate={handleCredentialsUpdate}
              />
            )}
          </EditBox>
          <EditBox
            title={
              <div className="flex items-center gap-2">
                <img src={checklistIcon} alt="pinflag" className="h-6 w-6" />
                <span>Formulario de facturación</span>
              </div>
            }
            width="w-1/3"
            description="Ordena y habilita los datos que necesitas para tu facturación"
            className="p-6"
          >
            <div className="flex flex-col px-8 gap-2">
              <TicketCheckBox
                checked={isCheckboxesDisabled || editedBillingFieldsRef.current.ssn}
                handleChange={() => handleBillingFieldChange('ssn')}
                text="Razón Social"
                disabled={isCheckboxesDisabled}
              />
              <TicketCheckBox
                checked={isCheckboxesDisabled || editedBillingFieldsRef.current.businessLine}
                handleChange={() => handleBillingFieldChange('businessLine')}
                text="Giro"
                disabled={isCheckboxesDisabled}
              />
              <TicketCheckBox
                checked={isCheckboxesDisabled || editedBillingFieldsRef.current.businessAddress}
                handleChange={() => handleBillingFieldChange('businessAddress')}
                text="Dirección"
                disabled={isCheckboxesDisabled}
              />
              <TicketCheckBox
                checked={isCheckboxesDisabled || editedBillingFieldsRef.current.businessName}
                handleChange={() => handleBillingFieldChange('businessName')}
                text="Teléfono"
                disabled={isCheckboxesDisabled}
              />
              <TicketCheckBox
                checked={isCheckboxesDisabled || editedBillingFieldsRef.current.businessState}
                handleChange={() => handleBillingFieldChange('businessState')}
                text="Región"
                disabled={isCheckboxesDisabled}
              />
              <TicketCheckBox
                checked={isCheckboxesDisabled ||
                  editedBillingFieldsRef.current.businessMunicipality}
                handleChange={() => handleBillingFieldChange('businessMunicipality')}
                text="Comuna"
                disabled={isCheckboxesDisabled}
              />
            </div>
          </EditBox>
          <EditBox
            title={
              <div className="flex items-center gap-2">
                <img src={emissionIcon} alt="pinflag" className="h-6 w-6" />
                <span>Emisión</span>
              </div>
            }
            width="w-1/3"
            description="Selecciona en qué estado de la compra deseas emitir cada documento"
            className="p-6"
          >
            <div className="flex flex-col p-2 gap-2">
              <div className="flex justify-start">Boleta</div>
              <div className="flex justify-start text-gray-400">Estado de la compra</div>
              <select
                value={editedInvoiceStatusRef.current}
                onChange={handleInvoiceStatusChange}
                className="w-full border border-gray-300 rounded-md p-2 text-sm"
              >
                {packageStatuses.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <div className="flex justify-start">Factura</div>
              <div className="flex justify-start text-gray-400">Estado de la compra</div>
              <select
                value={editedBillingStatusRef.current}
                onChange={handleBillingStatusChange}
                className="w-full border border-gray-300 rounded-md p-2 text-sm"
              >
                {packageStatuses.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </EditBox>
        </div>
      </EditBox>
    </div>
  )
}

export default TaxDocumentsEditBox

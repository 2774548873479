import React, { useContext } from 'react'
import { NumberParam, useQueryParam } from 'use-query-params'

import TabBar from '../../components/shared/TabBar/TabBar'
import Packages from '../../components/Distribution/Packages/Packages'
import Manifests from '../../components/Distribution/Manifests/Manifests'
import Stores from '../../components/Distribution/Stores/Stores'
import NoAccess from '../../components/NoAccess'
import { AuthContext } from '../../contexts/Store'
import RawLoader from '../../components/shared/Loader/RawLoader'

const Distribution = () => {
  const [tab=0, setTab] = useQueryParam('tab', NumberParam)
  const tabs = ['Paquetes', 'Tiendas', 'Manifiestos']
  const [subTab=0, setSubTab] = useQueryParam('subTab', NumberParam)
  const subTabs = ['Reposición', 'Retorno']
  const { hasAccess, loadingResources } = useContext(AuthContext)

  const distributionTabs = [
    <Packages type={subTab === 0 ? 'reposicion' : 'retorno'} />,
    <Stores />,
    <Manifests type={subTab === 0 ? 'reposicion' : 'retorno'} />
  ]

  if (!hasAccess('distribution')) {
    return (
      <div className="h-screen bg-light-grey">
        <NoAccess />
      </div>
    )
  }

  if (loadingResources) {
    return (
      <div className="h-screen bg-light-grey">
        <RawLoader />
      </div>
    )
  }

  return (
    <div className="bg-light-grey h-screen pt-8">
      <div className="mx-10 p-6 bg-white border flex gap-6">
        <div className="font-medium">Distribución</div>
        <TabBar tabs={tabs} tab={tab} setTab={setTab} />
      </div>
      <div className="mx-10 p-6 bg-white border flex gap-6">
        <TabBar tabs={subTabs} tab={subTab} setTab={setSubTab} />
      </div>
      {distributionTabs[tab]}
    </div>
  )
}

export default Distribution

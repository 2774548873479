import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ArrayParam, NumberParam, useQueryParam } from 'use-query-params'

import Button from '../../components/shared/Button'
import ActionsBar from '../../components/shared/Table/ActionsBar'
import useFetch from '../../hooks/useFetchParams'
import { getRefunds } from '../../helpers/request/refunds'
import {
  REFUNDS_STATES_TRANSLATION,
  REFUND_STATUSES,
  STATUS_COLOR,
  STATUS_ICONS
} from '../../constants/refunds'
import { isSubstring } from '../../utils/strings'
import SearchBar from '../../components/shared/SearchBar'
import { Table, TableRow } from '../../components/shared/Table/Table'
import { getFormattedDate } from '../../utils/dates'
import { searchTermsGenerator } from '../../utils/filters'
import PaginationFooter from '../../components/shared/Pagination/PaginationFooter'
import { sortArrayByKey } from '../../utils/arrays'
import { BILLING_ICONS, uploadFileIcon } from '../../constants/icons'
import ROUTES from '../../constants/routes'
import ToolTip from '../../components/shared/ToolTip'
import ChargeNote from '../../components/Refunds/Refunds/ChargeNote'
import SaleSearcher from '../../components/Refunds/Refunds/SaleSearcher'
import ExportRefunds from '../../components/Refunds/ExportRefunds'
import RefundsSelection from '../../components/Refunds/Refunds/RefundsSelection'

const Refunds = () => {
  const [refunds, setRefunds] = useState([])
  const [filters, setFilters] = useState([])
  const [searchResult, setSearchResult] = useState([])
  const [selectedStatus = [], setSelectedStatus] = useQueryParam('status', ArrayParam)
  const [page = 1, setPage] = useQueryParam('page', NumberParam)
  const [pageSize = 20, setPageSize] = useQueryParam('pageSize', NumberParam)
  const [searchBarInput, setSearchBarInput] = useState('')
  const [selectedRefund, setSelectedRefund] = useState([])
  const [selectedRefundWithSignedChargeNote, setSelectedRefundWithSignedChargeNote] = useState({})
  const [openUploadFile, setOpenUploadFile] = useState(false)
  const [openCreate, setOpenCreate] = useState(false)

  const navigate = useNavigate()

  const checkAll = Object.keys(selectedRefund).length === Math.min(refunds.length)

  const handleCheckAll = () => {
    if (!checkAll) {
      setSelectedRefund(refunds.map((refund) => {
        if (refund.errorMessage) return null

        return refund.id
      }).filter(Boolean))
    } else {
      setSelectedRefund([])
    }
  }

  const isChecked = (refund) => selectedRefund.includes(refund.id)

  const changeSelected = (refund) => {
    if (!isChecked(refund)) {
      setSelectedRefund((prevSelected) => [...prevSelected, refund.id])
    } else {
      setSelectedRefund(selectedRefund.filter((item) => item !== refund.id))
    }
  }

  const setRefundsData = useCallback((data) => {
    setRefunds(data.refunds)
    setFilters(data.filters)
    setSearchResult(data.refunds)
  }, [])

  const { isLoading, error } = useFetch(getRefunds, setRefundsData)

  const searchBarFilter = (refundsList, searchTerm) => {
    setSearchBarInput(searchTerm)
    if (searchTerm !== '') {
      const searchTerms = searchTermsGenerator(searchTerm)

      const searchedRefunds = refundsList.filter(
        (refund) => searchTerms.some((term) => (
          isSubstring(refund.orderId, term) ||
          isSubstring(refund.trackingId, term) ||
          isSubstring(refund.companyName, term)||
          isSubstring(refund.courierName, term)
        ))
      )
      setSearchResult(searchedRefunds)
    } else {
      setSearchResult(refundsList)
    }
  }

  const searchHandler = (searchTerm) => {
    setSearchBarInput(searchTerm)
    const refundsList = refunds
    searchBarFilter(refundsList, searchTerm)
  }

  const handleSort = (sortValues, order) => {
    const sortedCurrentRefunds = sortArrayByKey(searchResult, sortValues, order)

    setSearchResult(sortedCurrentRefunds)
    const sortedRefunds = sortArrayByKey(refunds, sortValues, order)

    setRefunds(sortedRefunds)
  }

  const sortValues = {
    ID: { key: 'id', type: 'number' },
    Estado: { key: 'status', type: 'string' },
    'O. venta': { key: 'orderId', type: 'string' },
    'O. transporte': { key: '', type: 'string' },
    Creación: { key: 'createdAt', type: 'date' },
    Empresa: { key: 'companyName', type: 'string' },
    Courier: { key: 'courier', type: 'string' }
  }

  const statusRefunds =
    selectedStatus.length === 0
      ? searchResult
      : searchResult.filter((refund) => selectedStatus.includes(refund.refundStatus))

  const currentRefunds = statusRefunds?.slice(pageSize * (page - 1), pageSize * page)

  return (
    <div className="relative h-screen flex flex-col bg-light-grey">
      <div className="flex justify-between mt-4 mx-10 border p-4 bg-white">
        <div>Indemnizaciones</div>
        <Button color="bg-normal-pinflag" onClick={() => setOpenCreate(true)} small>
          Solicitar indemnización
        </Button>
      </div>
      <ActionsBar
        statusFilters={REFUND_STATUSES}
        selectedFilters={selectedStatus}
        setSelectedFilters={setSelectedStatus}
        rightChildren={
          <div className="flex gap-1 my-auto ml-1">
            {!isLoading && (
              <ExportRefunds filtersData={filters} />
            )}
            <SearchBar
              searchbarInput={searchBarInput}
              searchKeyword={searchHandler}
              setSearchbarInput={(search) => {
                setSearchBarInput(search)
              }}
            />
          </div>
        }
      />
      {!isLoading && !error && currentRefunds.length === 0 ? (
        <div className="mx-10 border text-center py-8 bg-white text-s">
          No hay indemnizaciones con este criterio
        </div>
      ) : (
        <Table
          maxHeight="max-h-[60vh]"
          sortValues={sortValues}
          sortData={handleSort}
          headers={[
            '',
            <input type="checkbox" checked={checkAll} onChange={handleCheckAll} />,
            'ID',
            'Estado',
            'O. venta',
            'O. transporte',
            'Creación',
            'Empresa',
            'Courier',
            ''
          ]}
          isLoading={isLoading}
          error={error}
          minHeight
        >
          {currentRefunds.map((refund) => (
            <TableRow
              onClick={() => navigate(`${ROUTES.REFUNDS}/${refund.id}`)}
              key={refund.id}
              items={[
                refund.unsignedChargeNoteAvailable && !refund.signedChargeNoteAvailable ? (
                  <ToolTip
                  backgroundColor="bg-dark-pinflag"
                  textColor="text-white"
                  hoverElement={
                    <div className="flex gap-2 rounded-full w-max p-1 bg-normal-pinflag" />
                  }
                  right
                  >
                    <div className="whitespace-normal">Ya debes cargar la nota de cobro</div>
                  </ToolTip>
                ) : (
                  ' '
                ),
                <input type="checkbox" checked={isChecked(refund)} onChange={() => changeSelected(refund)} />,
                refund.id,
                <div
                  className={`flex w-max gap-2 rounded py-1 px-2 ${
                    STATUS_COLOR[refund.refundStatus]
                  }`}
                >
                  <img
                    src={STATUS_ICONS[refund.refundStatus]}
                    alt={refund.refundStatus}
                    className="my-auto w-5"
                  />
                  {REFUNDS_STATES_TRANSLATION[refund.refundStatus]}
                </div>,
                refund.orderId,
                refund.trackingId,
                getFormattedDate(refund.createdAt),
                refund.companyName,
                refund.courierName,
                refund.unsignedChargeNoteAvailable ? (
                  <Button
                    onClick={() => {
                      setSelectedRefundWithSignedChargeNote(refund)
                      setOpenUploadFile(true)
                    }}
                    border="border"
                    textColor="text-medium-dark-grey"
                    small
                  >
                    {refund.signedChargeNoteAvailable ? (
                      <img src={BILLING_ICONS.paid} alt="uploaded file" className="my-auto w-5" />
                    ) : (
                      <img src={uploadFileIcon} alt="upload file" className="my-auto w-5" />
                    )}
                  </Button>
                ) : (
                  ' '
                )
              ]}
            />
          ))}
        </Table>
      )}
      <PaginationFooter
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalItems={statusRefunds}
        pageItems={currentRefunds}
        />
      <ChargeNote
        refundId={selectedRefundWithSignedChargeNote.id}
        openUploadFile={openUploadFile}
        setOpenUploadFile={setOpenUploadFile}
        signedChargeNoteAvailable={selectedRefundWithSignedChargeNote.signedChargeNoteAvailable}
      />
      <SaleSearcher open={openCreate} handleClose={() => setOpenCreate(false)} />
      {selectedRefund.length > 0 && (
        <div className="absolute bottom-4 left-10">
          <RefundsSelection
            selectedRefunds={selectedRefund}
            handleCloseBar={() => {
              setSelectedRefund([])
            }}
          />
        </div>
      )}
    </div>
  )
}

export default Refunds

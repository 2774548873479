import React from 'react'

import { iconModalError } from '../../../constants/icons'
import Button from '../../shared/Button'
import Modal from '../../shared/Modal/Modal'

import { ERROR_SAVE_CREDENTIALS_TITLE, ERROR_SAVE_CREDENTIALS_SUBTITLE } from '../../../constants/integrationWelcome'

const ErrorSaveCredentialsModal = ({ show, handleClose }) => (
  <Modal show={show} handleClose={handleClose}>
    <div className="flex flex-col justify-center items-center my-4 max-w-md text-center space-y-4">
      <img alt="icon" src={iconModalError} className="mx-auto w-14 h-auto" />
      {ERROR_SAVE_CREDENTIALS_TITLE}
      {ERROR_SAVE_CREDENTIALS_SUBTITLE}
    </div>
    <div className="flex justify-center gap-2 my-8">
      <Button onClick={handleClose} color="bg-normal-pinflag" border="border border-normal-pinflag">
        Entendido
      </Button>
    </div>
  </Modal>
)

export default ErrorSaveCredentialsModal

import React from 'react'
import { useNavigate } from 'react-router-dom'

import { settingsWhiteIcon, startIntegrationIcon } from '../../../constants/icons'
import { INTEGRATIONS_TEXTS, CREDENTIALS_SAVE } from '../../../constants/integrationWelcome'
import Button from '../../shared/Button'
import ToggleSwitch from '../../shared/ToggleSwitch/ToggleSwitch.style'

const IntegrationResume = ({ logoImages, integrationName, integrationText, integrationPath,
  isCredentialsSave, isPixelActive, openSidebar, onToggleSwitch}) => {
  const navigate = useNavigate()

  const handleButtonClick = () => {
    if (!isCredentialsSave || integrationName === INTEGRATIONS_TEXTS.multivende_name) {
      navigate(integrationPath)
    } else {
      const sidebarContent = CREDENTIALS_SAVE[integrationName]
      if (sidebarContent) {
        openSidebar(sidebarContent)
      } else {
        console.error(`No se encontró configuración para ${integrationName}`)
      }
    }
  }

  const handleSwitch = () => {
    onToggleSwitch(integrationName, !isPixelActive)
  }

  return (
    <div
      className="
        flex flex-col shadow-lg border p-6 rounded-2xl my-6
        w-1/3
        min-h-64 max-h-80
        bg-white
      "
    >
      <div className="flex flex-row items-end mb-4">
        <div className="flex gap-4">
          {logoImages.map((image) => (
            <img
              key={image.alt}
              src={image.src}
              alt={image.alt}
              className={`h-10 ${image.className}`}
            />
          ))}
        </div>
        <div className="ml-4 font-semibold text-ultra-dark-grey text-lg">
          {integrationName}
        </div>
      </div>
      <div className="flex-grow overflow-hidden">
        <div className="py-2 text-justify mx-1.5 mb-4 text-sm">
          {integrationText}
        </div>
      </div>
      {isCredentialsSave ? (
        <div className='flex flex-row justify-between'>
          <Button
          color="bg-normal-pinflag"
          onClick={handleButtonClick}
        >
          <div className="flex flex-row items-center">
            <img src={settingsWhiteIcon} alt="setting icon" className="w-6 mr-5" />
            Configuraciones
          </div>
        </Button>
        {integrationName !== INTEGRATIONS_TEXTS.multivende_name && (
          <div className="flex gap-4 col-start-3 my-auto">
            <ToggleSwitch
              size="small"
              inactiveColor="#909AAA"
              check={isPixelActive}
              setCheck={handleSwitch}
            />
          </div>
        )}
        </div>
      ) : (
        <Button
          border="border border-normal-pinflag"
          color="bg-light-pinflag"
          onClick={handleButtonClick}
        >
          <div className="flex flex-row items-center text-normal-pinflag">
            <img src={startIntegrationIcon} alt="setting icon" className="w-6 mr-5" />
            Comenzar
          </div>
        </Button>
      )}
    </div>
  )
}

export default IntegrationResume

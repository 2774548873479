import React from 'react'

import { TableRow } from '../../shared/Table/Table'
import {
  STORE_CAPACITY_USAGE,
  STORE_STATUS,
  STORE_STATUS_COLORS,
  STORE_STATUS_TRANSLATE,
  STORE_STOCK
} from '../../../constants/stores'

const StoreRow = ({ store, headers, handleSelectStore }) => (
  <TableRow
    items={headers.map((header) => {
      const value = store[header.value]

      if (header.value === STORE_STATUS) {
        const { backgroundColor, textColor } = STORE_STATUS_COLORS[store.status]

        return (
          <div
            className="inline-flex rounded-full px-2 py-1 text-xs text-center items-center justify-center px-3"
            style={{
              backgroundColor,
              color: textColor,
              maxWidth: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {STORE_STATUS_TRANSLATE[store.status]}
          </div>
        )
      }

      if (header.value === STORE_STOCK) {
        if ([0, null, undefined].includes(store.totalAvailableStock)) {
          return (
            <div className="px-2 py-1 text-xs text-center items-center justify-center px-3">
              -
            </div>
          )
        }

        return (
          <div className="px-2 py-1 text-xs text-center items-center justify-center px-3">
            {store.totalAvailableStock}
          </div>
        )
      }

      if (header.value === STORE_CAPACITY_USAGE) {
        return (
          <div className="px-2 py-1 text-xs text-center items-center justify-center px-3">
            {store.capacityUsage}
          </div>
        )
      }

      return value
    })}
    onClick={() => handleSelectStore(store)}
  />
)

export default StoreRow

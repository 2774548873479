import React, { useState } from 'react'

import Modal from '../shared/Modal/Modal'
import Button from '../shared/Button'

const PROVIDER_CONFIGS = {
  getnet: {
    title: 'Credenciales Getnet',
    fields: [
      {
        section: 'Pago unitario',
        inputs: [
          { name: 'singlePaymentLogin', label: 'Login', placeholder: 'Ingrese su login' },
          {
            name: 'singlePaymentToken',
            label: 'Token de acceso',
            placeholder: 'Ingrese su token de acceso'
          }
        ]
      },
      {
        section: 'Pago oneclick',
        inputs: [
          { name: 'oneClickLogin', label: 'Login', placeholder: 'Ingrese su login' },
          {
            name: 'oneClickToken',
            label: 'Token de acceso',
            placeholder: 'Ingrese su token de acceso'
          }
        ]
      }
    ],
    formatInitialData: (config) => ({
      singlePaymentLogin: config?.paymentProviders?.getnetCredentials?.singlePayment?.login || '',
      singlePaymentToken: config?.paymentProviders?.getnetCredentials?.singlePayment?.token || '',
      oneClickLogin: config?.paymentProviders?.getnetCredentials?.oneClick?.login || '',
      oneClickToken: config?.paymentProviders?.getnetCredentials?.oneClick?.token || ''
    }),
    formatUpdateData: (formData, paymentProviders) => ({
      configuration: {
        paymentProviders: {
          ...paymentProviders,
          getnetCredentials: {
            singlePayment: {
              login: formData.singlePaymentLogin,
              token: formData.singlePaymentToken
            },
            oneClick: {
              login: formData.oneClickLogin,
              token: formData.oneClickToken
            }
          }
        }
      }
    })
  },
  klap: {
    title: 'Credenciales Klap',
    fields: [
      {
        inputs: [{ name: 'apiKey', label: 'Api key', placeholder: 'Ingrese su api key' }]
      }
    ],
    formatInitialData: (config) => ({
      apiKey: config?.paymentProviders?.klapCredentials?.apiKey || ''
    }),
    formatUpdateData: (formData, paymentProviders) => ({
      configuration: {
        paymentProviders: {
          ...paymentProviders,
          klapCredentials: {
            apiKey: formData.apiKey
          }
        }
      }
    })
  },
  webpay: {
    title: 'Credenciales Webpay',
    fields: [
      {
        inputs: [
          { name: 'apiKey', label: 'Api key', placeholder: 'Ingrese su api key' },
          {
            name: 'commerceCode',
            label: 'Código de comercio',
            placeholder: 'Ingrese su código de comercio'
          },
          {
            name: 'transactionCode',
            label: 'Código de Transacción completa',
            placeholder: 'Ingrese su código de transacción completa'
          },
          {
            name: 'oneClickCode',
            label: 'Código de OneClick',
            placeholder: 'Ingrese su código de OneClick'
          }
        ]
      }
    ],
    formatInitialData: (config) => ({
      apiKey: config?.paymentProviders?.webpayCredentials?.apiKey || '',
      commerceCode: config?.paymentProviders?.webpayCredentials?.commerceCode || '',
      transactionCode: config?.paymentProviders?.webpayCredentials?.transactionCode || '',
      oneClickCode: config?.paymentProviders?.webpayCredentials?.oneClickCode || ''
    }),
    formatUpdateData: (formData, paymentProviders) => ({
      configuration: {
        paymentProviders: {
          ...paymentProviders,
          webpayCredentials: {
            apiKey: formData.apiKey,
            commerceCode: formData.commerceCode,
            transactionCode: formData.transactionCode,
            oneClickCode: formData.oneClickCode
          }
        }
      }
    })
  },
  bsale: {
    title: 'Credenciales BSale',
    fields: [
      {
        inputs: [{ name: 'accessToken', label: 'Api key', placeholder: 'Ingrese su api key' }]
      }
    ],
    formatInitialData: (config) => ({
      accessToken: config?.electronicTaxDocumentProvider?.accessToken || ''
    }),
    formatUpdateData: (formData, currentConfig) => ({
      configuration: {
        electronicTaxDocumentProvider: {
          provider: currentConfig?.electronicTaxDocumentProvider?.provider,
          accessToken: formData.accessToken,
          emissionStatuses: currentConfig?.electronicTaxDocumentProvider?.emissionStatuses
        }
      }
    })
  }
}

const ProviderCredentialsModal = ({
  provider,
  configuration,
  isOpen,
  closeModal,
  onSuccess,
  onUpdate
}) => {
  const providerConfig = PROVIDER_CONFIGS[provider]
  const [formData, setFormData] = useState(providerConfig.formatInitialData(configuration))
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(null)

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)
    setError(null)

    try {
      const updateData =
        provider === 'bsale'
          ? providerConfig.formatUpdateData(formData, configuration)
          : providerConfig.formatUpdateData(formData, configuration.paymentProviders)
      onUpdate(updateData)
      onSuccess()
      closeModal()
    } catch (err) {
      setError('Error al actualizar las credenciales. Por favor intente nuevamente.')
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Modal show={isOpen} handleClose={closeModal} title={providerConfig.title}>
      <div className="border-t my-4">
        <div className="flex justify-end gap-4 mt-4">
          <form className="flex flex-col gap-6 px-20">
            <div className="flex flex-col gap-4 justify-center items-center">
              <div className="text-sm text-dark-grey font-medium">
                Completa el formulario con los datos correspondientes.
              </div>

              <div className="flex flex-col gap-4 w-full">
                {providerConfig.fields.map((field) => (
                  <div key={field.section} className="flex flex-col gap-4">
                    {field.section && <div className="font-semibold text-md">{field.section}</div>}
                    {field.inputs.map((input) => (
                      <div key={input.name}>
                        <div className="font-normal text-md text-dark-grey">{input.label}</div>
                        <input
                          className="border border-normal-grey rounded-lg w-full p-2 text-md"
                          type="text"
                          name={input.name}
                          value={formData[input.name]}
                          onChange={handleChange}
                          placeholder={input.placeholder}
                          required
                        />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>

            {error && <div className="text-red-500 text-sm text-center">{error}</div>}

            <div className="flex justify-end">
              <Button
                color="bg-normal-pinflag"
                type="submit"
                disabled={isSubmitting}
                onClick={handleSubmit}
                small
              >
                {isSubmitting ? 'Guardando...' : 'Guardar'}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  )
}

export default ProviderCredentialsModal

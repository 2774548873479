import React, { useState, useCallback, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { AuthContext } from '../../contexts/Store'

import NoAccess from '../../components/NoAccess'
import LoadingError from '../../components/shared/LoadingError'
import RawLoader from '../../components/shared/Loader/RawLoader'
import ROUTES from '../../constants/routes'
import useFetch from '../../hooks/useFetchParams'
import { getStore } from '../../helpers/request/stores'
import { backArrow, STORE_ICONS } from '../../constants/icons'
import StoreDetailsLists from '../../components/Stores/StoreDetail/StoreDetailsLists'
import StoreDetailsCard from '../../components/Stores/StoreDetail/StoreCard/StoreDetailsCard'
import Button from '../../components/shared/Button'

const RawWarehousePage = () => {
  const { warehouseId } = useParams()
  const previousRoute = ROUTES.STORES
  const storeEditRoute = `${ROUTES.STORES}/${warehouseId}/configuracion`

  const [store, setStore] = useState(null)

  const { hasAccess, loadingResources } = useContext(AuthContext)

  const setPointData = useCallback((data) => {
    setStore(data)
  }, [])

  const { isLoading, error } = useFetch(getStore, setPointData, warehouseId)

  const navigate = useNavigate()

  if (!hasAccess('warehouses')) {
    return (
      <div className="h-screen bg-light-grey">
        <NoAccess />
      </div>
    )
  }

  if (error) return <LoadingError />

  return (
    <div>
      {isLoading || loadingResources || !store ? (
        <div className="loader flex h-screen items-center justify-center">
          <RawLoader />
        </div>
      ) : (
        <div className="bg-light-grey">
          <div className="flex items-center mx-4 my-4">
            {previousRoute && (
              <div>
                <div
                  className="h-5 w-6 mr-4"
                  onClick={() => navigate(previousRoute)}
                  role="button"
                  tabIndex="0"
                >
                  <img alt="back-arrow" src={backArrow} />
                </div>
              </div>
            )}
            <div className="text-ultra-dark-grey text-sm">Tiendas</div>
            <div className="text-ultra-dark-grey text-sm mr-2">/</div>
            <div className="text-ultra-dark-grey text-sm font-medium mr-2">Detalle</div>
          </div>
          <div className="flex flex-col mx-8 bg-white">
            <div className="flex py-2 border border-gray-200 justify-between">
              <div className="flex flex-col border-gray-200 py-1 px-4">
                <div className="text-sm text-gray-400 mb-1">Tienda</div>
                <div className="text-lg text-ultra-dark-grey mb-1">{store.StoreWarehouse?.storeId} {store.name}</div>
              </div>
              <div className="flex flex-col border-gray-200 py-1 px-4 justify-center">
                <Button
                  color="bg-white"
                  border="border border-gray-200"
                  onClick={() => navigate(storeEditRoute)}
                >
                  <div className="flex justify-center items-center text-ultra-dark-grey">
                    <img src={STORE_ICONS.configuration} alt="upload" className="h-4 w-4 mx-2" />
                    Configuración
                  </div>
                </Button>
              </div>
            </div>
            <StoreDetailsCard store={store} />
          </div>
          <div className="flex flex-col mx-8 bg-white border border-gray-200 my-4">
            <StoreDetailsLists store={store} />
          </div>
        </div>
      )}
    </div>
  )
}

export default RawWarehousePage

import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import Button from '../../shared/Button'
import { createRefund } from '../../../helpers/request/refunds'
import ResponseModal from '../../shared/ResponseModal/ResponseModal'
import FilePicker from '../../shared/FilePicker/FilePicker'
import RawLoader from '../../shared/Loader/RawLoader'
import useFetch from '../../../hooks/useFetchParams'
import { getHoldingBankData } from '../../../helpers/request/holding'
import { BANK_ACCOUNT_TYPES } from '../../../constants/refunds'

const RefundForm = ({ packageId, setStep, refundedProducts, setRefundId, initialRefundAmount}) => {
  const [error, setError] = useState(false)
  const [paymentProof, setPaymentProof] = useState(null)
  const [evidenceFiles, setEvidenceFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const [holdingBankData, setHoldingBankData] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [isEditable, setIsEditable] = useState(false)

  useFetch(getHoldingBankData, setHoldingBankData)

  const handleEvidenceFiles = async (objectFiles) => {
    const newFiles = [...objectFiles]
    setEvidenceFiles([...evidenceFiles, ...newFiles])
  }

  const removeEvidenceFile = (index) => {
    const newFiles = [...evidenceFiles]
    newFiles.splice(index, 1)
    setEvidenceFiles(newFiles)
  }

  const addProductsToFormData = (formData) => {
    const refundProducts = refundedProducts.map((product) => ({
      sku: product.sku,
      cost: product.price,
      quantity: product.quantity,
      name: product.productName
    }))

    formData.append('refundProducts', JSON.stringify(refundProducts))
  }

  const addFilesToFormData = (formData) => {
    formData.append('files', paymentProof)
    for (const evidenceFile of evidenceFiles) {
      formData.append('files', evidenceFile)
    }
  }

  const makeFormData = (data) => {
    const formData = new FormData()
    addProductsToFormData(formData)
    addFilesToFormData(formData)
    formData.append('packageId', packageId)
    formData.append('companyId', '1')
    for (const formItem of Object.entries(data)) {
      if (formItem[0] === 'bankAccountData') {
        formData.append('bankAccountData', JSON.stringify(formItem[1]))
      } else {
        formData.append(formItem[0], formItem[1])
      }
    }
    return formData
  }

  const onSubmit = async (data) => {
    const body = makeFormData(data)
    setLoading(true)
    try {
      const response = await createRefund(body)
      setError(false)
      setRefundId(response.id)
      setStep(3)
    } catch(errorSubmit) {
      setErrorMessage(errorSubmit.message)
      setError(true)
    }
    setLoading(false)
  }

  const handleEditToggle = () => setIsEditable((prev) => !prev)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      claimedRefund: initialRefundAmount
    }
  })

  const fieldClassName =
    'mb-2 w-full px-2 py-1 rounded border text-medium-dark-grey font-light text-s'

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col text-s">
        Detalle
        <input
          {...register('refundReason', { required: true })}
          className={`${fieldClassName} ${errors.refundReason ? 'border-red' : ''}`}
        />
        <div className="flex items-center gap-2">
          Monto a solicitar
          {!isEditable && (
            <button
              type="button"
              onClick={handleEditToggle}
              className="text-blue underline text-xs"
            >
              Editar
            </button>
          )}
        </div>
        <input
          {...register('claimedRefund', { required: true })}
          className={`${fieldClassName} ${errors.claimedRefund ? 'border-red' : ''} ${
            !isEditable ? 'bg-gray-100 cursor-not-allowed' : ''
          }`}
          readOnly={!isEditable}
        />
        <div className="my-2">Datos bancarios</div>
        {holdingBankData ? (
          <div className="mb-4 py-2 px-4 text-xs bg-semi-light-grey rounded w-fit">
              {Object.values(holdingBankData).map((accountData) => (
                <div key={accountData}>{accountData}</div>
              ))}
            </div>
        ) : (
          <div className="grid grid-cols-2 gap-3 mb-2">
            <div className="flex flex-col">
              Nombre destinatario
              <input
                {...register('bankAccountData.ownerName', { required: true })}
                className={`${fieldClassName} ${errors.bankAccountData?.ownerName ? 'border-red' : ''}`}
              />
              Banco
              <input
                {...register('bankAccountData.bankName', { required: true })}
                className={`${fieldClassName} ${errors.bankAccountData?.bankName ? 'border-red' : ''}`}
              />
              Tipo de cuenta
              <select
                {...register('bankAccountData.accountType', { required: true })}
                className={`${fieldClassName} ${
                  errors.bankAccountData?.accountType ? 'border-red' : ''
                }`}
              >
                {BANK_ACCOUNT_TYPES.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col">
              Email
              <input
                {...register('bankAccountData.ownerEmail', { required: true })}
                className={`${fieldClassName} ${errors.bankAccountData?.ownerEmail ? 'border-red' : ''}`}
              />
              Rut
              <input
                {...register('bankAccountData.ownerSsn', { required: true })}
                className={`${fieldClassName} ${errors.bankAccountData?.ownerSsn ? 'border-red' : ''}`}
              />
              Nro. de cuenta
              <input
                {...register('bankAccountData.accountNumber', { required: true })}
                className={`${fieldClassName} ${
                  errors.bankAccountData?.accountNumber ? 'border-red' : ''
                }`}
              />
            </div>
          </div>
        )}
        <div className="flex gap-4 mb-5">
          <div>
            Adjunta comprobante de pago:
            <FilePicker
              editing
              handleFile={(fileContent) => setPaymentProof(fileContent[0])}
              file={paymentProof}
              isLoaded={!!paymentProof}
              download
            />
          </div>
          <div>
            Adjunta evidencia (opcional):
            <FilePicker
              editing
              files={evidenceFiles}
              handleFile={handleEvidenceFiles}
              removeFile={removeEvidenceFile}
              multiple
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-2">
        <Button color="bg-dark-grey" onClick={() => setStep(1)}>
          Volver
        </Button>
        {paymentProof && !loading && (
          <Button color="bg-normal-pinflag" submit>
            Solicitar Reembolso
          </Button>
        )}
        {loading && <RawLoader />}
      </div>
      <ResponseModal
      isModalOpen={error}
      handleClose={() => setError(false)}
      errorMessage={errorMessage}
      error />
    </form>
  )
}

export default RefundForm

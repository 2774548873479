import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import ROUTES from '../../../../constants/routes'
import { PixelCredentialsContext } from '../../../../contexts/PixelCredentialsContext'
import { META_PIXEL_CREDENTIALS_STEPS, CONFIGURATION_STEPS_UP, CONFIGURATION_STEPS_DOWN, META_PIXEL_NAME } from '../../../../constants/metaPixelCredential'
import ErrorSaveCredentialsModal from '../../../../components/Settings/Integrations/ErrorSaveCredentialsModal'
import MetaPixelCredentialsStep from '../../../../components/Settings/Integrations/MetaPixelCredentialsStep'
import PixelStepper from '../../../../components/Settings/Integrations/PixelStepper'
import SuccessSaveCredentialsModal from '../../../../components/Settings/Integrations/SuccessSaveCredentialsModal'
import useSubmitPixelCredentials from '../../../../components/Settings/useSubmitPixelCredentials'
import Button from '../../../../components/shared/Button'
import RawLoader from '../../../../components/shared/Loader/RawLoader'
import SectionHeader from '../../../../components/shared/SectionHeader2'
import { parsePixelCredentials, getUpperStepMeta, validateInputMeta } from '../../../../helpers/pixelCredentials'

const MetaPixelCredentials = () => {

  const { companyId,
    pixels,
    setPixelCredentials,
    setPixelIsActive,
    setPixelHasCredentialsSave
  } = useContext(PixelCredentialsContext)

  const { hasCredentialsSave } = pixels.facebook

  const [step, setStep] = useState(0)
  const [isLoadingStep, setIsLoadingStep] = useState(false)
  const [error, setError] = useState('')
  const [inputValues, setInputValues] = useState({
    pixelId: '',
    pixelAccessToken: ''
  })

  const upperStepperSteps = CONFIGURATION_STEPS_UP.slice(0, 3)
  const lowerStepperStepsMeta = CONFIGURATION_STEPS_DOWN.slice(0, 5)

  const navigate = useNavigate()

  const { onSubmit, openResponseModal, setOpenResponseModal, loadingSubmit, errorSubmit } =
  useSubmitPixelCredentials()

  const handleInputChange = (id, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [id]: value
    }))
  }

  const validateInputValue = () => {
    const stepError = validateInputMeta(step, inputValues)
    setError(stepError)
    if (stepError === '') return true
    return false
  }

  const upperStep = getUpperStepMeta(step)

  const submitCredentials = async () => {
    const parseCredentials = parsePixelCredentials(companyId, inputValues, META_PIXEL_NAME, true)
    await onSubmit(parseCredentials,
      (newValues) => setPixelCredentials(META_PIXEL_NAME, newValues),
      (value) => setPixelHasCredentialsSave(META_PIXEL_NAME, value),
      (value) => setPixelIsActive(META_PIXEL_NAME, value),
      true)
  }

  const handleBack = () => {
    if (step === 0) {
      navigate(-1)
    } else {
      setStep(step - 1)
    }
  }

  const handleContinue = () => {
    if (hasCredentialsSave) {
      setStep(step + 1)
    }
    const isValidInput = validateInputValue()
    if (isValidInput) {
      if (step === 5) {
        submitCredentials()
      } else {
          setStep(step + 1)
      }
    }
  }

  const handleStepClick = (index) => {
    let nextStep = 0
    if (index >= 1 && index <= 2) {
      nextStep = 1
    } else if (index >= 3) {
      nextStep = 2
    }
    setStep(nextStep)
  }

  const goToIntegrations = () => {
    navigate(ROUTES.INTEGRATIONS)
  }

  const handleModalClose = () => {
    setOpenResponseModal(false)
    setStep(6)
  }

  const handleModalCloseError = () => {
    setOpenResponseModal(false)
  }

  useEffect(() => {
    setIsLoadingStep(true)
    const timeout = setTimeout(() => {
      setIsLoadingStep(false)
    }, 500)

    return () => clearTimeout(timeout)
  }, [step])

  return (
    <div className="flex flex-col h-screen">
      <SectionHeader title="Integraciones" />
      <div className="bg-general-background py-8 px-16 grow">
        <div className="bg-white p-3 rounded-lg mb-4 flex justify-center">
          <PixelStepper steps={upperStepperSteps} step={upperStep} setStep={handleStepClick}
          isLowerStepper={false}/>
        </div>

        {isLoadingStep ? (
          <div className="flex items-center justify-center h-full">
            <RawLoader />
          </div>
          ) : (
          <div className="flex flex-col justify-between bg-white p-12 rounded-lg h-max">
            <MetaPixelCredentialsStep
              step={
                (step === 3 || step === 5) && hasCredentialsSave
                  ? META_PIXEL_CREDENTIALS_STEPS[step === 3 ? 7 : 8]
                  : META_PIXEL_CREDENTIALS_STEPS[step]
              }
              inputValues={inputValues}
              handleInputChange={handleInputChange}
              error={error}
              hasCredentialsSave={hasCredentialsSave}
            />
            {(step >= 1 && step <= 5) && (
              <div className="bg-white p-3 rounded-lg mb-4 flex justify-center">
                <PixelStepper steps={lowerStepperStepsMeta} step={step - 1} setStep={setStep}
                isLowerStepper/>
              </div>
            )}
            <div className="flex justify-end space-x-4">
              <Button
                border="border border-normal-pinflag"
                textColor="text-normal-pinflag"
                onClick={handleBack}
              >
                &lt;&lt; Volver
              </Button>
              {step < 6 && (
                <Button
                  border="border border-normal-pinflag"
                  color="bg-normal-pinflag"
                  onClick={() => handleContinue()}
                >
                  Continuar &gt;&gt;
                </Button>
              )}
              {step === 6 && (
                <Button
                  border="border border-normal-pinflag"
                  color="bg-normal-pinflag"
                  onClick={() => goToIntegrations()}
                >
                  Finalizar &gt;&gt;
                </Button>
              )}
            </div>
          </div>
        )}
      </div>

      {loadingSubmit && (
        <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 bg-gray-900 z-50">
          <RawLoader />
        </div>
      )}
      {errorSubmit && (
        <ErrorSaveCredentialsModal show={openResponseModal} handleClose={handleModalCloseError}/>
      )}
      {!loadingSubmit && !errorSubmit && (
        <SuccessSaveCredentialsModal show={openResponseModal} handleClose={handleModalClose}
        isFirstTime/>
      )}
    </div>
  )
}

export default MetaPixelCredentials

import styled from 'styled-components'
import RawToggleSwitch from './RawToggleSwitch'
import { COLORS } from '../../../constants/styles'

const ToggleSwitch = styled(RawToggleSwitch)`
  .switch {
    position: relative;
    display: inline-block;
    ${(props) =>
      props.size === 'small' &&
      `width: 42px;
        height: 24px;`}
        ${(props) =>
      props.size === 'ultra-small' &&
      `width: 32px;
        height: 16px;`}
    ${(props) =>
      (!props.size || props.size === 'medium') &&
      `width: 60px;
        height: 34px;`}
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    ${(props) =>
      props.inactiveColor
        ? `background-color: ${props.inactiveColor};`
        : `background-color: ${COLORS.RED};`}

    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    ${(props) =>
      props.size === 'small' &&
      `width: 20px;
        height: 20px;
        left: 2.5px;
        bottom: 2.5px;`}
        ${(props) =>
      props.size === 'ultra-small' &&
      `width: 12px;
        height: 12px;
        left: 2.5px;
        bottom: 2.5px;`}
    ${(props) =>
      (!props.size || props.size === 'medium') &&
      `height: 28px;
        width: 28px;
        left: 3px;
        bottom: 3px;`}
  }

  input:checked + .slider {
    background-color: ${COLORS.GREEN_ACTIVE};
  }

  input:focus + .slider {
    box-shadow: 0 0 1px ${COLORS.PINFLAG};
  }

  input:checked + .slider:before {
    ${(props) =>
      props.size === 'ultra-small' &&
      `-webkit-transform: translateX(15px);
        -ms-transform: translateX(15px);
        transform: translateX(15px);`}
    ${(props) =>
      props.size === 'small' &&
      `-webkit-transform: translateX(18px);
        -ms-transform: translateX(18px);
        transform: translateX(18px);`}
    ${(props) =>
      (!props.size || props.size === 'medium') &&
      `-webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);`}
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .dot {
    height: 17px;
    width: 17px;
    border-radius: 50%;
    display: inline-block;
    align-self: center;
  }

  .online {
    background-color: ${COLORS.GREEN_ACTIVE};
  }

  .offline {
    ${(props) =>
      props.inactiveColor
        ? `background-color: ${props.inactiveColor};`
        : `background-color: ${COLORS.RED};`}
  }
`

export default ToggleSwitch

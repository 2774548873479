import React from 'react'

const EditBox = ({ title, description, children, rightItem, width, className }) => (
  <div className={`rounded-md ${width || 'w-80'}`}>
    <div className="text-ultra-dark-grey font-medium text-sm p-4 border border-gray-200 rounded-t-md">
      {rightItem ? (
        <div className="flex justify-between">
          <div>{title}</div>
          {rightItem}
        </div>
      ) : (
        title
      )}
    </div>
    <div className={`border rounded-b-md ${className || ''}`} style={{ boxShadow: 'inset 0px 8px 12px -8px rgba(50, 50, 50, 0.2)' }}>
      <div className="text-dark-grey text-xs p-4">{description}</div>
      <div className="text-sm text-ultra-dark-grey px-4 pb-4">{children}</div>
    </div>
  </div>
)

export default EditBox

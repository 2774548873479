import randomApi from '../../axiosConfig/randomApi'

export const getCompanyConfiguration = async (config) => {
  const { params: companyId } = config

  const response = await randomApi().get(`/companies/${companyId}/configuration`)

  return response.data
}

export const putCompanyConfiguration = async (companyId, body) => {
  if (Object.keys(body).length === 0) return null

  const response = await randomApi().put(`/companies/${companyId}/configuration`, body)

  return response.data
}

import React, { useState, useEffect, useMemo } from 'react'
import secureLocalStorage from 'react-secure-storage'

import SearchBar from '../../shared/SearchBar'
import { searchTermsGenerator } from '../../../utils/filters'
import { isSubstring } from '../../../utils/strings'
import useFetch from '../../../hooks/useFetchParams'
import RawLoader from '../../shared/Loader/RawLoader'
import LoadingError from '../../shared/LoadingError'
import { getPersonalization } from '../../../helpers/request/personalization'
import { STOCKS } from '../../../constants/stocks'
import { ORDERS } from '../../../constants/sales'

import StoreStocksList from './StoreTables/StoreStocksList'
import StoreOrdersList from './StoreTables/StoreOrdersList'
import StoreDetailsFilters from './StoreDetailFilters'

const StoreDetailsLists = ({ store }) => {
  const [selectedList, setSelectedList] = useState(ORDERS)
  const [isFiltered, setIsFiltered] = useState(false)
  const [searchBarInput, setSearchBarInput] = useState('')
  const [filteredStocks, setFilteredStocks] = useState(store.StoreWarehouse?.variantStocks)
  const [filteredOrders, setFilteredOrders] = useState(store.packages)
  const [stocksSearchResults, setStocksSearchResults] = useState(
    store.StoreWarehouse?.variantStocks
  )
  const [ordersSearchResults, setOrdersSearchResults] = useState(store.packages)
  const [stocksPage, setStocksPage] = useState(1)
  const [ordersPage, setOrdersPage] = useState(1)
  const [stocksPageSize, setStocksPageSize] = useState(10)
  const [ordersPageSize, setOrdersPageSize] = useState(10)
  const [personalization, setPersonalization] = useState({})
  const [managesStock, setManagesStock] = useState(false)
  const [securityStock, setSecurityStock] = useState(0)
  const companyId = secureLocalStorage.getItem('companiesIds')

  useEffect(() => {
    setFilteredStocks(store.StoreWarehouse?.variantStocks)
    setFilteredOrders(store.packages)
    setStocksSearchResults(store.StoreWarehouse?.variantStocks)
    setOrdersSearchResults(store.packages)
  }, [store, selectedList])

  useEffect(() => {
    setIsFiltered(isFiltered)
  }, [isFiltered])

  const {
    isLoading: isPersonalizationLoading,
    error: personalizationError
  } = useFetch(getPersonalization, setPersonalization, companyId)

  const isReady = useMemo(
    () => !isPersonalizationLoading && !personalizationError,
    [isPersonalizationLoading, personalizationError]
  )

  useEffect(() => {
    if (isReady && personalization?.stockFlow.active && personalization?.stockFlow.security_stock) {
      setManagesStock(true)
      setSelectedList(ORDERS)
      setSecurityStock(personalization.stockFlow.security_stock)
    }
  }, [isReady, personalization])

  const stocksSearchBarFilter = (stocksList, searchTerm) => {
    setSearchBarInput(searchTerm)
    if (searchTerm !== '') {
      const searchTerms = searchTermsGenerator(searchTerm)
      const searchedStocks = stocksList.filter(
        (stock) => searchTerms.some((term) => (
          isSubstring(stock.sku, term) ||
          isSubstring(stock.name, term)
      )))

      setStocksSearchResults(searchedStocks)
    } else {
      setStocksSearchResults(stocksList)
    }
  }

  const ordersSearchBarFilter = (ordersList, searchTerm) => {
    setSearchBarInput(searchTerm)
    if (searchTerm !== '') {
      const searchTerms = searchTermsGenerator(searchTerm)
      const searchedOrders = ordersList.filter(
        (order) => searchTerms.some((term) => (
          isSubstring(order.codbultocliente, term) ||
          isSubstring(order.customer.name, term)
      )))

      setOrdersSearchResults(searchedOrders)
    } else {
      setOrdersSearchResults(ordersList)
    }
  }

  const searchHandler = (searchTerm) => {
    setSearchBarInput(searchTerm)

    if (selectedList === STOCKS) {
      const stocksList = isFiltered ? filteredStocks : store.StoreWarehouse?.variantStocks
      stocksSearchBarFilter(stocksList, searchTerm)
    } else if (selectedList === ORDERS) {
      const ordersList = isFiltered ? filteredOrders : store.packages
      ordersSearchBarFilter(ordersList, searchTerm)
    }
  }

  const handleFilterChange = (stocksListData, isFilteredArg = false) => {
    if (selectedList === STOCKS) {
      const stocksList = (isFiltered || isFilteredArg)
        ? stocksListData
        : store.StoreWarehouse.variantStocks
      setFilteredStocks(stocksList)
      stocksSearchBarFilter(stocksList, searchBarInput)
    } else {
      const stocksList = (isFiltered || isFilteredArg)
        ? stocksListData
        : store.packages
      setFilteredOrders(stocksList)
      ordersSearchBarFilter(stocksList, searchBarInput)
    }
  }

  let ordersLabelColor

  if (selectedList === ORDERS) {
    ordersLabelColor = 'text-primary'
  } else if (managesStock) {
    ordersLabelColor = 'text-gray-400'
  } else {
    ordersLabelColor = 'text-primary'
  }

  return (
    <div className="flex flex-col space-y-4">
      {isPersonalizationLoading && <RawLoader />}
      {personalizationError && <LoadingError />}
      {!isPersonalizationLoading && !personalizationError && (
        <>
          <div className="rounded justify-stretch flex bg-white px-3 py-2">
            <div className="flex space-x-4 my-2 ml-4 mr-6 items-center">
              {managesStock && (
                <div
                  className={`text-sm ${selectedList === STOCKS ? 'text-primary' : 'text-gray-400'}`}
                  onClick={() => setSelectedList(STOCKS)}
                  role="button"
                  tabIndex="0"
                >
                  Inventario
                </div>
              )}
              <div
                className={`
                  text-sm ${ordersLabelColor}`}
                onClick={() => setSelectedList(ORDERS)}
                role="button"
                tabIndex="0"
              >
                Pedidos
              </div>
            </div>
            <div className="flex space-x-4 my-2 ml-6">
              <div className="flex items-center space-x-4">
                <StoreDetailsFilters
                  store={store}
                  handleFilterChange={handleFilterChange}
                  isFiltered={isFiltered}
                  setIsFiltered={setIsFiltered}
                  selectedList={selectedList}
                />
                <SearchBar
                  searchbarInput={searchBarInput}
                  searchKeyword={searchHandler}
                />
              </div>
            </div>
            {(selectedList === STOCKS && managesStock) && (
              <div className="flex items-center space-x-4 w-full text-end">
                <div className="w-full text-xs text-gray-600 self-center">
                  * Las existencias tienen restado el stock de seguridad
                </div>
              </div>
            )}
          </div>
          <div className="bg-white">
            {(selectedList === STOCKS && managesStock) && (
              <StoreStocksList
                stocks={filteredStocks}
                setStocks={setFilteredStocks}
                searchResult={stocksSearchResults}
                setSearchResult={setStocksSearchResults}
                pageSize={stocksPageSize}
                page={stocksPage}
                setPage={setStocksPage}
                setPageSize={setStocksPageSize}
                securityStock={securityStock}
              />
            )}
            {selectedList === ORDERS && (
              <StoreOrdersList
                orders={filteredOrders}
                setOrders={setFilteredOrders}
                searchResult={ordersSearchResults}
                setSearchResult={setOrdersSearchResults}
                pageSize={ordersPageSize}
                page={ordersPage}
                setPage={setOrdersPage}
                setPageSize={setOrdersPageSize}
              />
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default StoreDetailsLists

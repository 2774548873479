import React, { useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment/moment'
import secureLocalStorage from 'react-secure-storage'

import useForm from '../../hooks/useForm'
import { checkEmail } from '../../utils/strings'
import { ORDER_CREATION_URL } from '../../constants/urls'
import ROUTES from '../../constants/routes'
import RawLoader from '../../components/shared/Loader/RawLoader'
import useFetch from '../../hooks/useFetchParams'
import { getStates } from '../../helpers/request/states'
import RawProductTabBar from '../../components/Sales/SalesOrderCreation/ProductTabBar/RawProductTabBar'
import RawProductForm from '../../components/Sales/SalesOrderCreation/ProductForm/RawProductForm'
import Button from '../../components/shared/Button'
import ResponseModal from '../../components/shared/ResponseModal/ResponseModal'
import randomApi from '../../axiosConfig/randomApi'
import { validateProductForm } from '../../helpers/sales'
import { getClickAndCollectPoints, getWarehouses } from '../../helpers/request/warehouse'
import HeaderTitle from '../../components/shared/HeaderTitle'
import { activeWarehouses } from '../../helpers/warehouse'
import { AuthContext } from '../../contexts/Store'
import NoAccess from '../../components/NoAccess'
import { getCompanyCouriers } from '../../helpers/request/couriers'

const SalesOrderCreation = () => {
  const [errorAPI, setErrorAPI] = useState(false)
  const countryCode = 56
  const [phoneNumber, setPhoneNumber] = useState('')
  const [cities, setCities] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [productShown, setProductShown] = useState(0)
  const [products, setProducts] = useState([
    {
      name: '',
      price: '',
      description: '',
      photos: '',
      code: '',
      weight: ''
    }
  ])
  const [errorMessage, setErrorMessage] = useState('')
  const [warehouses, setWarehouses] = useState([])
  const [deliveryType, setDeliveryType] = useState('homeDelivery')
  const [companyCouriers, setCompanyCouriers] = useState([])
  const [clickAndCollectPoints, setClickAndCollectPoints] = useState([])

  const { hasAccess, loadingResources } = useContext(AuthContext)

  const navigate = useNavigate()

  useFetch(getStates, setCities)

  const params = useMemo(() => ({ type: 'pickupPoint' }), [])

  useFetch(getWarehouses, setWarehouses, params)

  useFetch(getCompanyCouriers, setCompanyCouriers)

  useFetch(getClickAndCollectPoints, setClickAndCollectPoints)

  const initialForm = {
    orderId: '',
    companyId: '',
    name: '',
    lastName: '',
    email: '',
    phone: '',
    deliveryAddress: '',
    instructions: '',
    totalPrice: '',
    state: '',
    city: 0,
    purchaseDate: new Date(),
    packageId: '',
    warehouseId: null
  }

  // Validations
  // eslint-disable-next-line react-func/max-lines-per-function
  const validationForm = (form) => {
    const errors = {}
    if (form.companyId === '') {
      errors.companyId = 'Debes elegir una empresa'
    }
    if (form.name?.length === 0) {
      errors.name = 'El campo \'Nombre\' es requerido'
    }
    if (form.lastName?.length === 0) {
      errors.lastName = 'El campo \'Apellido\' es requerido'
    }
    if (!form.email?.trim()) {
      errors.email = 'El campo \'Email\' es requerido'
    }
    if (!checkEmail(form.email)) {
      errors.email = 'El campo \'Email\' debe tener un correo válido'
    }
    if (form.phone?.length !== 11) {
      errors.phone = 'El campo \'Telefono\' debe tener 9 dígitos'
    }
    if (form.totalPrice === 0) {
      errors.totalPrice = 'Debe ingresar el precio total'
    }
    if (deliveryType !== 'homeDelivery') {
      if (!form.warehouseId) {
        errors.warehouse = 'Debe seleccionar una tienda'
      }
    } else {
      if (!form.deliveryAddress?.trim()) {
        errors.deliveryAddress = 'El campo \'Dirección\' es requerido'
      }
      if (form.state === '') {
        errors.state = 'Debe seleccionar una region'
      }
      if (!form.city) {
        errors.city = 'Debe seleccionar una comuna'
      }
    }
    if (form.orderId.charAt(0).toLowerCase() === 'p') {
      errors.orderId = 'El número de orden no debe comenzar con P'
    }
    return errors
  }

  const loggedCompanies = secureLocalStorage.getItem('companies')

  const { form, errors, handleBlur, handleChange, saveChange, setErrors } = useForm(
    initialForm,
    validationForm
  )

  const stateCouriers = form.state
    ? Object.values(companyCouriers).find((courier) => courier.stateId === parseInt(form.state, 10))
    : null

  const couriers = stateCouriers
    ? Array.from(new Set(stateCouriers.services.map((service) => service.courier.name)))
    : []

  const services =
    stateCouriers && form.courier
      ? stateCouriers.services.filter(
          (service) =>
            service.courier.name === form.courier &&
            service.serviceType !== 'distribution' &&
            service.serviceType !== 'distribution-return'
        )
      : []

  const addProduct = () => {
    setProducts((previousProducts) => [
      ...previousProducts,
      {
        name: '',
        price: '',
        description: '',
        photos: '',
        code: '',
        weight: ''
      }
    ])
  }

  const removeProduct = (id) => {
    setProductShown(0)
    setProducts((previousProducts) => previousProducts.filter((_, index) => index !== id))
  }

  // eslint-disable-next-line react-func/max-lines-per-function
  const makeRequestBody = () => {
    const body = {
      companyId: form.companyId,
      type: 'ecommerce',
      clientDetails: {
        name: form.name,
        lastName: form.lastName,
        email: form.email,
        phone: form.phone
      },
      warehouseId: form.warehouseId,
      payments: {
        cost: parseInt(form.totalPrice, 10),
        datePurchase: form.purchaseDate
      },
      products: products.map((product) => ({
        ...product,
        photos: product.photos ? [product.photos] : [],
        price: parseFloat(product.price),
        weight: parseFloat(product.weight)
      }))
    }
    if (deliveryType !== 'homeDelivery') body.warehouseId = form.warehouseId
    else {
      body.delivery = {
        address: form.deliveryAddress,
        cityId: form.city,
        instructions: form.instructions,
        courier: form.courier,
        service: form.service
      }
      if (form.courier && form.service) {
        body.delivery.courier = form.courier
        body.delivery.service = form.service
      }
    }
    if (form.orderId !== '') body.orderId = form.orderId
    return body
  }

  const handleSubmitErrors = (errorResponse) => {
    setErrors({ orderId: errorResponse.response?.data?.message || undefined })
    setErrorMessage(errorResponse.response?.data?.message || '-')
    setIsModalOpen(true)
    setErrorAPI(true)
  }

  const runValidations = () => {
    const errorsForm = validationForm(form)

    const productsValidation = products.some((product) => {
      const errorsProduct = validateProductForm(product)
      if (Object.keys(errorsProduct).length !== 0) return true
      return false
    })

    if (Object.keys(errorsForm).length !== 0 || productsValidation) {
      setErrors(errorsForm)
      return false
    }
    return true
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    if (!runValidations()) return

    setIsLoading(true)
    const body = makeRequestBody()
    randomApi()
      .post(ORDER_CREATION_URL, body)
      .then(() => {
        setErrorAPI(false)
        setIsModalOpen(true)
      })
      .catch((errorResponse) => {
        handleSubmitErrors(errorResponse)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleClose = () => {
    setIsModalOpen(false)
  }

  const availableClickAndCollectStates = Object.entries(cities)
    .filter(([, value]) =>
      value.cities.some((city) => Object.keys(clickAndCollectPoints).includes(String(city.id)))
    )
    .map(([, value]) => value.stateId)

  if (!hasAccess('sales')) {
    return (
      <div className="h-screen bg-light-grey">
        <NoAccess />
      </div>
    )
  }

  if (loadingResources) {
    return (
      <div className="mt-10">
        <RawLoader />
      </div>
    )
  }

  return (
    <div className="min-h-screen bg-light-grey">
      <HeaderTitle
        title="Ventas"
        subtitle="Crea una orden de venta"
        goBack={() => navigate(ROUTES.SALES)}
      />
      <div className="mx-16 m-auto">
        <div className="my-6 bg-white border p-6 rounded">
          <div className="mb-2">Crear orden de venta</div>
          <div className="text-xs text-dark-grey">
            Completa los campos con la información respectiva a la nueva orden de venta
          </div>
        </div>
        <form onSubmit={handleSubmit} className="flex flex-col gap-6">
          <div className="bg-white border p-6 rounded">
            <div className="mb-6">Información de la empresa</div>
            <div className="grid grid-cols-2 gap-4 text-sm">
              <div>
                <div className="text-ultra-dark-grey font-light">Empresa:</div>
                <select
                  className="w-full border border-normal-grey rounded-lg p-1.5"
                  value={form.companyId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="companyId"
                  required
                >
                  <option hidden value="">
                    Selecciona la empresa
                  </option>
                  {loggedCompanies.map((companyItem) => (
                    <option key={companyItem.id} value={companyItem.id}>
                      {companyItem.name}
                    </option>
                  ))}
                </select>
                {errors.companyId && <p className="text-xs text-red">{errors.companyId}</p>}
              </div>
              <div>
                <div className="text-ultra-dark-grey font-light">N° Orden (opcional):</div>
                <input
                  className="w-full border border-normal-grey rounded-lg p-1.5"
                  value={form.orderId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  name="orderId"
                />
                {errors.orderId && <p className="text-xs text-red">{errors.orderId}</p>}
              </div>
            </div>
          </div>
          <div className="bg-white border p-6 rounded">
            <div className="mb-6">Información del cliente</div>
            <div className="grid grid-cols-2 gap-4 text-sm">
              <div>
                <div className="text-ultra-dark-grey font-light">Nombre:</div>
                <input
                  className="w-full border border-normal-grey rounded-lg p-1.5"
                  value={form.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  type="text"
                  name="name"
                />
                {errors.name && <p className="text-xs text-red">{errors.name}</p>}
              </div>
              <div>
                <div className="text-ultra-dark-grey font-light">Apellido:</div>
                <input
                  className="w-full border border-normal-grey rounded-lg p-1.5"
                  value={form.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  type="text"
                  name="lastName"
                />
                {errors.lastName && <p className="text-xs text-red">{errors.lastName}</p>}
              </div>
              <div>
                <div className="text-ultra-dark-grey font-light">Email:</div>
                <input
                  className="w-full border border-normal-grey rounded-lg p-1.5"
                  value={form.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  type="text"
                  name="email"
                />
                {errors.email && <p className="text-xs text-red">{errors.email}</p>}
              </div>
              <div>
                <div className="text-ultra-dark-grey font-light">Teléfono:</div>
                <div className="flex">
                  <div className="p-1.5 border border-normal-grey rounded-lg">+56</div>
                  <input
                    className="w-full border border-normal-grey rounded-lg p-1.5"
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value)
                      saveChange(e.target.name, countryCode + e.target.value)
                    }}
                    onBlur={handleBlur}
                    type="number"
                    required
                    name="phone"
                  />
                </div>
                {errors.phone && <p className="text-xs text-red">{errors.phone}</p>}
              </div>
            </div>
          </div>
          <div className="bg-white border p-6 rounded">
            <div className="mb-6">Información de envío</div>
            <div className="grid grid-cols-2 gap-4 text-sm mb-4">
              <div>
                <div className="text-ultra-dark-grey font-light">Tipo de envío:</div>
                <select
                  className="w-full border border-normal-grey rounded-lg p-1.5"
                  value={deliveryType}
                  onChange={(e) => setDeliveryType(e.target.value)}
                >
                  <option value="homeDelivery">Envío a domicilio</option>
                  <option value="storePickUp">Retiro en tienda</option>
                  <option value="clickAndCollect">Envío Click and Collect</option>
                </select>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 text-sm">
              {deliveryType === 'storePickUp' && (
                <div>
                  <div className="text-ultra-dark-grey font-light">Tienda de entrega:</div>
                  <select
                    className="w-full border border-normal-grey rounded-lg p-1.5"
                    value={form.warehouseId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="warehouseId"
                    required
                  >
                    <option hidden value="">
                      Selecciona la tienda
                    </option>
                    {activeWarehouses(warehouses).map((warehouse) => (
                      <option key={warehouse.id} value={warehouse.id}>
                        {warehouse.name}
                      </option>
                    ))}
                  </select>
                  {errors.warehouse && <p className="text-xs text-red">{errors.warehouse}</p>}
                </div>
              )}
              {deliveryType === 'clickAndCollect' && (
                <>
                  <div>
                    <div className="text-ultra-dark-grey font-light">Región de entrega:</div>
                    <select
                      className="w-full border border-normal-grey rounded-lg p-1.5"
                      value={form.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="state"
                      required
                    >
                      <option hidden value="">
                        Selecciona la región
                      </option>
                      {Object.values(cities)
                        .filter((stateItem) =>
                          availableClickAndCollectStates.includes(stateItem.stateId)
                        )
                        .map((regionItem) => (
                          <option key={regionItem.stateId} value={regionItem.stateId}>
                            {regionItem.name}
                          </option>
                        ))}
                    </select>
                    {errors.state && <p className="text-xs text-red">{errors.state}</p>}
                  </div>
                  <div>
                    <div className="text-ultra-dark-grey font-light">Comuna de entrega:</div>
                    <select
                      className="w-full border border-normal-grey rounded-lg p-1.5"
                      value={form.city}
                      onChange={(e) => {
                        e.target.value = parseInt(e.target.value, 10)
                        handleChange(e)
                      }}
                      disabled={!form.state}
                      type="text"
                      name="city"
                    >
                      <option hidden value="0">
                        Selecciona la comuna
                      </option>
                      {!!form.state &&
                        cities[form.state].cities
                          .filter((city) =>
                            Object.keys(clickAndCollectPoints).includes(String(city.id))
                          )
                          .map((city) => (
                            <option key={city.id} value={city.id}>
                              {city.name}
                            </option>
                          ))}
                    </select>
                  </div>
                  <div>
                    <div className="text-ultra-dark-grey font-light">Punto de entrega:</div>
                    <select
                      className="w-full border border-normal-grey rounded-lg p-1.5"
                      value={form.warehouseId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="warehouseId"
                      disabled={!form.city}
                      required
                    >
                      <option hidden value="">
                        Selecciona el punto
                      </option>
                      {!!form.city &&
                        clickAndCollectPoints[form.city].map((warehouse) => (
                          <>
                            <option key={warehouse.id} value={warehouse.id}>
                              {warehouse.name}
                            </option>
                            <option disabled className="text-xs mb-2 text-dark-grey border-b">
                              {warehouse.address}
                            </option>
                          </>
                        ))}
                    </select>
                    {errors.warehouse && <p className="text-xs text-red">{errors.warehouse}</p>}
                  </div>
                </>
              )}
              {deliveryType === 'homeDelivery' && (
                <>
                  <div>
                    <div className="text-ultra-dark-grey font-light">Dirección de entrega:</div>
                    <input
                      className="w-full border border-normal-grey rounded-lg p-1.5"
                      value={form.deliveryAddress}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      type="text"
                      name="deliveryAddress"
                      placeholder="Calle y número"
                    />
                    {errors.deliveryAddress && (
                      <p className="text-xs text-red">{errors.deliveryAddress}</p>
                    )}
                  </div>
                  <div>
                    <div className="text-ultra-dark-grey font-light">
                      Indicaciones de entrega (opcional):
                    </div>
                    <input
                      className="w-full border border-normal-grey rounded-lg p-1.5"
                      value={form.instructions}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      name="instructions"
                      placeholder="Depto/Oficina/Condominio"
                    />
                  </div>
                  <div>
                    <div className="text-ultra-dark-grey font-light">Región de entrega:</div>
                    <select
                      className="w-full border border-normal-grey rounded-lg p-1.5"
                      value={form.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="state"
                      required
                    >
                      <option hidden value="">
                        Selecciona la región
                      </option>
                      {Object.values(cities).map((regionItem) => (
                        <option key={regionItem.stateId} value={regionItem.stateId}>
                          {regionItem.name}
                        </option>
                      ))}
                    </select>
                    {errors.state && <p className="text-xs text-red">{errors.state}</p>}
                  </div>
                  <div>
                    <div className="text-ultra-dark-grey font-light">Comuna de entrega:</div>
                    <select
                      required
                      className="w-full border border-normal-grey rounded-lg p-1.5"
                      value={form.city}
                      onChange={(e) => {
                        e.target.value = parseInt(e.target.value, 10)
                        handleChange(e)
                      }}
                      disabled={!form.state}
                      onBlur={(e) => {
                        e.target.value = parseInt(e.target.value, 10)
                        handleBlur(e)
                      }}
                      type="text"
                      name="city"
                    >
                      <option hidden value="0">
                        Selecciona la comuna
                      </option>
                      {!!form.state &&
                        cities[form.state].cities.map((city) => (
                          <option key={city.id} value={city.id}>
                            {city.name}
                          </option>
                        ))}
                    </select>
                    {errors.city && <p className="text-xs text-red">{errors.city}</p>}
                  </div>
                  <div>
                    <div className="text-ultra-dark-grey font-light">Courier (opcional):</div>
                    <select
                      className="w-full border border-normal-grey rounded-lg p-1.5"
                      value={form.courier}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      name="courier"
                      disabled={!form.state}
                    >
                      <option hidden value="">
                        Selecciona el courier
                      </option>
                      {form.state &&
                        couriers.map((courier) => (
                          <option key={courier} value={courier}>
                            {courier}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div>
                    <div className="text-ultra-dark-grey font-light">Servicio (opcional):</div>
                    <select
                      className="w-full border border-normal-grey rounded-lg p-1.5"
                      value={form.service}
                      disabled={!form.courier}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      name="service"
                    >
                      <option hidden value="">
                        Selecciona el servicio
                      </option>
                      {form.courier &&
                        services.map((courier) => (
                          <option key={courier.serviceType} value={courier.serviceType}>
                            {courier.serviceType}
                          </option>
                        ))}
                    </select>
                  </div>
                </>
              )}
              <div>
                <div className="text-ultra-dark-grey font-light">Precio total de venta:</div>
                <input
                  className="w-full border border-normal-grey rounded-lg p-1.5"
                  value={form.totalPrice}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="number"
                  required
                  min="0"
                  step="1"
                  name="totalPrice"
                />
                {errors.totalPrice && <p className="text-xs text-red">{errors.totalPrice}</p>}
              </div>
              <div>
                <div className="text-ultra-dark-grey font-light">Fecha de compra:</div>
                <input
                  className="w-full border border-normal-grey rounded-lg p-1.5"
                  value={moment(form.purchaseDate).format('YYYY-MM-DD')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="date"
                  name="purchaseDate"
                />
              </div>
            </div>
          </div>
          <div className="my-4 bg-white border p-6 rounded">
            <div className="mb-6">
              <p>Información de producto</p>
            </div>
            <RawProductTabBar
              products={products}
              productShown={productShown}
              setProductShown={setProductShown}
              addProduct={addProduct}
            />
            <RawProductForm
              id={productShown}
              products={products}
              setProducts={setProducts}
              removeProduct={removeProduct}
            />
          </div>
          <div className="flex flex-col mb-12 mt-4">
            <div className="self-end">
              {isLoading ? (
                <RawLoader />
              ) : (
                <Button color="bg-normal-pinflag" submit>
                  Crear
                </Button>
              )}
            </div>
          </div>
        </form>
        <ResponseModal
          isModalOpen={isModalOpen}
          handleClose={handleClose}
          error={errorAPI}
          route={ROUTES.SALES}
          successMessage="¡Has creado una orden satisfactoriamente!"
          errorMessage={errorMessage}
        />
      </div>
    </div>
  )
}

export default SalesOrderCreation

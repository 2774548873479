import React, { useCallback, useState, useEffect } from 'react'

import { SALES_DETAIL_ICONS, PACKAGE_STATUS_ICONS } from '../../../constants/icons'
import { PACKAGE_STATUS_TRANSLATE } from '../../../constants/packageStatus'
import { CLICK_AND_COLLECT_STATES, HOME_DELIVERY_STATES } from '../../../constants/sales'
import { getCourierLogs } from '../../../helpers/request/sales'
import { saleStatusColorText, saleStatusIconColor, saleStatusColor } from '../../../helpers/sales'
import { getFullFormattedStringDate } from '../../../utils/dates'
import { capitalizeFirstLetter } from '../../../utils/strings'
import LogsTimeline from '../../Sales/SalesDetails/LogsTimeline'
import TimeLine from '../../Sales/SalesDetails/TimeLine'
import Button from '../../shared/Button'
import Modal from '../../shared/Modal/Modal'

const OrderInTimeModal = ({ show, sale, handleNextStep, handleClose }) => {
  const [courierLogs, setCourierLogs] = useState([])
  const [orderStatus, setOrderStatus] = useState(null)
  const fetchLogs = useCallback(async (data) => {
    try {
      const logsData = await getCourierLogs(data.pinflagId)
      setCourierLogs(logsData)
    } catch {
      setCourierLogs([])
    }
  }, [])

  useEffect(() => {
    if (sale && sale.pinflagId) {
      fetchLogs(sale)
      setOrderStatus(PACKAGE_STATUS_TRANSLATE[sale.packageStatus])
    }
  }, [sale, fetchLogs])

  const deliveryDate = capitalizeFirstLetter(getFullFormattedStringDate(sale.promisedDeliveryDate))
  return (
    <Modal show={show} handleClose={handleClose}>
      <div
        className="w-[60vw] max-w-2xl h-[80vh] max-h-[80vh] bg-white flex flex-col overflow-hidden"
      >
        <div className="flex-grow overflow-y-auto p-4">
        <div className="text-lg bg-light-grey flex flex-col p-5 pb-10 mb-4">
          <span className="bg-light-grey flex flex-col text-base text-ultra-dark-grey font-normal">
            Esta orden no se encuentra atrasada
          </span>
          <div className="flex flex-row justify-between items-center w-full">
            <div className='flex flex-row font-normal text-base text-center items-center w-1/2 space-x-4'>
              <div className="flex text-ultra-dark-grey">
                {sale.saleOrder}
              </div>
              <div className="">
                <span className={`ml-2 w-fit px-2 py-1 rounded
                  ${saleStatusColor(sale.packageStatus)}`}>
                  {orderStatus}
                </span>
              </div>
            </div>
            <div className={`flex flex-col bg-light-green px-12 py-2 border-l-4 border-green
              rounded-md w-1/2`}>
              <span className="text-xs font-normal text-grey-600">Fecha estimada de entrega</span>
              <span className="font-normal text-black text-base">{deliveryDate}</span>
            </div>
          </div>
        </div>
        <div className="border bg-white p-6 rounded-t">
          <div className="flex gap-2 mb-2 font-medium">
            <img src={SALES_DETAIL_ICONS.timeline} alt="" className="w-5 my-auto" />
            Seguimiento
          </div>
          <TimeLine
            availableStatus={
              sale.clickAndCollect ? CLICK_AND_COLLECT_STATES : HOME_DELIVERY_STATES
            }
            history={sale.packageHistory}
            currentStatus={sale.packageStatus}
            currentColor={saleStatusColorText(sale.packageStatus)}
            currentIcon={{
              icon: PACKAGE_STATUS_ICONS[sale.packageStatus],
              style: saleStatusIconColor(sale.packageStatus)
            }}
          />
        </div>
        <div className="border py-2 bg-white">
          <LogsTimeline steps={courierLogs} />
        </div>
        </div>
        <div className="sticky bottom-0 bg-white flex gap-2 justify-end pt-4 border-t w-full">
          <Button onClick={handleNextStep}
          border="border border-gray-200"
          textColor="text-dark-grey"
          width="w-1/5"
          className="opacity-75 hover:opacity-50">
            Crear solicitud
          </Button>
          <Button onClick={handleClose}
          color="bg-normal-pinflag"
          border="border border-normal-pinflag"
          width="w-1/5"
          className="opacity-75 hover:opacity-50">
            Volver
          </Button>
        </div>
      </div>
    </Modal>
  )
}
export default OrderInTimeModal

import { INTEGRATIONS_TEXTS, PIXEL_ID_NAME, PURCHASE_NAME } from '../constants/integrationWelcome'
import { updatePixelEvent, getPixelCredentials } from './request/stats'
import { postPersonalization } from './request/personalization'

export const parsePixelCredentials = (companyId, pixelValues, pixelName, isActive) => ({
    'company_id': companyId,
    'pixel_name': pixelName,
    'new_credentials': {
        ...pixelValues,
        isActive
    }
})

export const getUpperStepGA4 = (step) => {
    let upperStep = 0
    if (step === 0) {
        upperStep = 0
    } else if (step >= 1 && step < 6) {
        upperStep = 1
    } else if (step === 6) {
        upperStep = 2
    } else if (step === 7) {
        upperStep = 0
    } else if (step > 7 && step < 11) {
        upperStep = 1
    } else if (step >= 11 && step <= 12) {
        upperStep = 2
    }
    return upperStep
}

export const getUpperStepMeta = (step) => {
    let upperStep = 0
    if (step === 0) {
        upperStep = 0
    } else if (step >= 1 && step < 3) {
        upperStep = 1
    } else {
        upperStep = 2
    }
    return upperStep
}

const validateNotEmpty = (value) => {
    if (!value) {
        return 'Este campo no puede estar vacío.'
    }
    return ''
}

const validateMeasurementID = (value) => {
    const emptyError = validateNotEmpty(value)
    if (emptyError) return emptyError

    const regex = /^G-[A-Za-z0-9]+$/
    if (!regex.test(value)) return 'El ID de medición no es válido. Debe seguir el formato G-XXXXXXXX.'
    return ''
}

const validateSecretValue = (value) => {
    const emptyError = validateNotEmpty(value)
    if (emptyError) return emptyError

    const regex = /^[A-Za-z0-9]{10,30}$/
    if (!regex.test(value)) return 'El valor secreto no es válido. Debe tener entre 10 y 30 caracteres y solo contener letras y números.'
    return ''
}

export const validateInputGA4 = (step, inputValues) => {
    let stepError = ''
    if (step === 4) {
        stepError = validateMeasurementID(inputValues.pixelId)
    } else if (step === 6) {
        stepError = validateSecretValue(inputValues.pixelAccessToken)
    }

    if (stepError) {
      return(stepError)
    }
    return('')
}

export const validatePixelID = (pixelID) => {
    const emptyError = validateNotEmpty(pixelID)
    if (emptyError) {
        return emptyError
    }

    const regex = /^[0-9]{10,20}$/
    if (!regex.test(pixelID)) {
      return 'El Pixel ID debe contener solo números y tener entre 10 y 20 caracteres.'
    }

    return ''
}

export const validateAccessToken = (accessToken) => {
    const emptyError = validateNotEmpty(accessToken)
    if (emptyError) return emptyError
    if (accessToken.length <= 180) return 'El Access Token debe tener más de 180 caracteres.'
    if (!accessToken.startsWith('EAA')) return 'El Access Token debe comenzar con "EAA".'
    return ''
}

export const validateInputMeta = (step, inputValues) => {
    let stepError = ''
    if (step === 3) {
        stepError = validateAccessToken(inputValues.pixelAccessToken)
    } else if (step === 5) {
        stepError = validatePixelID(inputValues.pixelId)
    }

    if (stepError) {
      return(stepError)
    }
    return('')
}

export const validateInputSavecredentials = (inputValues, inputName, integrationName) => {
    if (integrationName === INTEGRATIONS_TEXTS.meta_name) {
        if (inputName === PIXEL_ID_NAME) {
            return validateInputMeta(5, inputValues)
        }
        return validateInputMeta(3, inputValues)
    } if (integrationName === INTEGRATIONS_TEXTS.ga4_name) {
        if (inputName === PIXEL_ID_NAME) {
            return validateInputGA4(4, inputValues)
        }
        return validateInputGA4(6, inputValues)
    }
    return ''
}

export const hasPixelCredentials = (credentials) => {
    if (credentials.pixelId && credentials.pixelAccessToken) return true
    return false
}

export const getPixelNameByIntegrationName = (integrationName) => {
    switch (integrationName) {
      case INTEGRATIONS_TEXTS.ga4_name:
        return INTEGRATIONS_TEXTS.ga4_pixel_name
      case INTEGRATIONS_TEXTS.meta_name:
        return INTEGRATIONS_TEXTS.meta_pixel_name
      default:
        return null
    }
}

export const parseUpdatePixelEvent = (companyId, eventName, value) => ({
    'company_id': companyId,
    'events': {
        [eventName]: value
    }
})

const checkIsActiveStatus = (data) => {
    const relevantKeys = [INTEGRATIONS_TEXTS.ga4_pixel_name, INTEGRATIONS_TEXTS.meta_pixel_name]
    return relevantKeys.some((key) => data[key]?.isActive === true)
}

export const changePurchaseEventValue = async (companyId, toggleValue) => {
    if (toggleValue) {
        const updateEventValue = parseUpdatePixelEvent(companyId, PURCHASE_NAME, toggleValue)
        await updatePixelEvent(updateEventValue)
        await postPersonalization(companyId, {
            usePixelEvents: toggleValue
        })
    } else {
        const data = await getPixelCredentials({ params: companyId })
        const value = checkIsActiveStatus(data)
        if (!value) {
            const updateEventValue = parseUpdatePixelEvent(companyId, PURCHASE_NAME, value)
            await updatePixelEvent(updateEventValue)
            await postPersonalization(companyId, {
                usePixelEvents: value
            })
        }
    }
}

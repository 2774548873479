import React from 'react'

import Button from '../components/shared/Button'
import { ASSETS_URL } from './others'

export const CONFIGURATION_STEPS_UP = ['Empecemos', 'Ingreso', 'Conexión']
export const CONFIGURATION_STEPS_DOWN = ['', '', '', '', '', '']
export const GA4_PIXEL_NAME = 'ga4'

export const GA4_PIXEL_CREDENTIALS_IMAGES = {
  logoAnalytics: `${ASSETS_URL}/pixelCredentials/GA4/logoAnalytics.svg`,
  analytics: `${ASSETS_URL}/pixelCredentials/GA4/analytics.svg`,
  step1: `${ASSETS_URL}/pixelCredentials/GA4/step1.svg`,
  step2: `${ASSETS_URL}/pixelCredentials/GA4/step2.svg`,
  step3: `${ASSETS_URL}/pixelCredentials/GA4/step3.svg`,
  step4: `${ASSETS_URL}/pixelCredentials/GA4/step4.svg`,
  step5: `${ASSETS_URL}/pixelCredentials/GA4/step5.svg`,
  step6: `${ASSETS_URL}/pixelCredentials/GA4/step6.svg`,
  logoAds: `${ASSETS_URL}/pixelCredentials/GA4/logoAds.svg`,
  ads: `${ASSETS_URL}/pixelCredentials/GA4/ads.svg`,
  step1Ads: `${ASSETS_URL}/pixelCredentials/GA4/step1Ads.svg`,
  step2Ads: `${ASSETS_URL}/pixelCredentials/GA4/step2Ads.svg`,
  step3Ads: `${ASSETS_URL}/pixelCredentials/GA4/step3Ads.svg`,
  step4Ads: `${ASSETS_URL}/pixelCredentials/GA4/step4Ads.svg`
}

export const GA4_PIXEL_CREDENTIALS_STEPS = [
  {
    id: 0,
    logo: <div className="flex gap-4 mb-5">
            <img src={GA4_PIXEL_CREDENTIALS_IMAGES.logoAnalytics} alt="GA4 and Analytics Logo" className="w-30" />
            <img src={GA4_PIXEL_CREDENTIALS_IMAGES.logoAds} alt="Ads Logo" className="w-50" />
          </div>,
    title: 'Integración a Google Analytics y Google Ads',
    substeps: [
      {
        description: (
        <>
            Te damos la bienvenida a la integración de Google Analytics y Google Ads
            <br />
            A continuación, te guiaremos a través de los pasos para que puedas{' '}
            aprovechar al máximo estas integraciones.
            <br />
            <br />
            *Es bueno saber que si no tienes activado Google Analytics no funcionara la integración{' '}
            con Google Ads, es por esto que te recomendamos integrarlas en conjunto.
            <br />
            <br />
            Para comenzar debes ingresar a Google Analytics.
        </>
        ),
        content: (
          <div className="py-6 w-1/6">
            <Button
              color="bg-normal-pinflag"
              width="w-full"
              onClick={() =>
              window.open(
                  'https://analytics.google.com/analytics/web/'
              )
              }
            >
            Ir a Google Analytics
          </Button>
        </div>
        )
      }
    ]
  },
  {
    id: 1,
    title: 'Ingreso',
    substeps: [
      {
        description: (
        <>
            Sigue los siguientes pasos para obtener los datos necesarios.
            <br />
            <br />
            <ol style={{listStyleType: 'decimal', listStylePosition: 'inside' }}>
                <li>Accede al menú principal en <span className="font-bold">Administrar (Imagen 1)</span>.</li>
            </ol>
        </>
        ),
        content: <img src={GA4_PIXEL_CREDENTIALS_IMAGES.step1} alt="step1" className="w-[100%]" />
      }
    ]
  },
  {
    id: 2,
    title: 'Ingreso',
    substeps: [
      {
        description: (
          <ol start="2" style={{listStyleType: 'decimal', listStylePosition: 'inside' }}>
            <li>Luego selecciona <span className="font-bold">Flujos de datos</span> en la sección{' '}
            <span className="font-bold">Recogida y modificación de datos (Imagen 2)</span>.</li>
          </ol>
        ),
        content: <img src={GA4_PIXEL_CREDENTIALS_IMAGES.step2} alt="step2" className="w-[100%]" />
      }
    ]
  },
  {
    id: 3,
    title: 'Ingreso',
    substeps: [
      {
        description: (
          <ol start="3" style={{listStyleType: 'decimal', listStylePosition: 'inside' }}>
            <li>En Flujos de datos selecciona la <span className="font-bold">página web correspondiente{' '}
                (Imagen 3)</span> (esto abrirá una sección que dice: Detalles del flujo web).
            </li>
          </ol>
        ),
        content: <img src={GA4_PIXEL_CREDENTIALS_IMAGES.step3} alt="step3" className="w-[100%]" />
      }
    ]
  },
  {
    id: 4,
    title: 'Ingreso',
    substeps: [
      {
        description: (
          <ol start="4" style={{listStyleType: 'decimal', listStylePosition: 'inside' }}>
            <li>En Detalles del flujo web, <span className="font-bold">copia el ID de medición de{' '}
                los detalles del flujo (Imagen 4)</span>.
            </li>
          </ol>
        ),
        content: <img src={GA4_PIXEL_CREDENTIALS_IMAGES.step4} alt="step4" className="w-[100%]" />
      },
      {
        description: (
          <ol start="5" style={{listStyleType: 'decimal', listStylePosition: 'inside' }}>
            <li>Ingresa el ID de medición que acabas de copiar en el siguiente recuadro:</li>
          </ol>
        )
      }
    ]
  },
  {
    id: 5,
    title: 'Ingreso',
    substeps: [
      {
        description: (
          <ol start="6" style={{listStyleType: 'decimal', listStylePosition: 'inside' }}>
            <li>Luego ve a la sección <span className="font-bold">Secretos de la API de{' '}
                Measurement Protocol (Imagen 5)</span>.
            </li>
          </ol>
        ),
        content: <img src={GA4_PIXEL_CREDENTIALS_IMAGES.step5} alt="step5" className="w-[100%]" />
      }
    ]
  },
  {
    id: 6,
    title: 'Conexión',
    substeps: [
      {
        description: (
          <ol start="7" style={{listStyleType: 'decimal', listStylePosition: 'inside' }}>
            <li>Crea un valor secreto ingresando el nombre deseado y{' '}
                <span className="font-bold">copia el valor generado (Imagen 6)</span>.
            </li>
          </ol>
        ),
        content: <img src={GA4_PIXEL_CREDENTIALS_IMAGES.step6} alt="step6" className="w-[100%]" />
      },
      {
        description: (
          <ol start="8" style={{listStyleType: 'decimal', listStylePosition: 'inside' }}>
            <li>Ingresa el valor secreto asignado en el siguiente recuadro:</li>
          </ol>
        )
      }
    ]
  },
  {
    id: 7,
    logo: <img src={GA4_PIXEL_CREDENTIALS_IMAGES.logoAds} alt="Ads Logo" className="w-55 mb-5" />,
    title: 'Integración Google Ads',
    substeps: [
      {
        description: (
        <>
            Ahora daremos inicio a la integración con Google Ads.
            <br />
            Recuerda que debes tener vinculada tu cuenta de Google Analytics con Google Ads{' '}
            para que todo funcione correctamente.
            <br />
            <br />
            <br />
            Para comenzar debes ingresar a Google Ads.
        </>
        ),
        content: (
        <Button
            color="bg-normal-pinflag"
            onClick={() =>
            window.open(
                'https://ads.google.com/nav/selectaccount'
            )
            }
        >
            Ir a Google Ads
        </Button>
        )
      }
    ]
  },
  {
    id: 8,
    title: 'Ingreso',
    substeps: [
      {
        description: (
        <>
            Sigue los siguientes pasos para obtener los datos necesarios.
            <br />
            <br />
            <ol start="1" style={{listStyleType: 'decimal', listStylePosition: 'inside' }}>
                <li>Accede al menú principal en <span className="font-bold">Objetivos</span>, luego en{' '}
                <span className="font-bold">Resumen</span> y selecciona <span className="font-bold">Crear{' '}
                    acción de conversión (Imagen 1)</span>.</li>
            </ol>
        </>
        ),
        content: <img src={GA4_PIXEL_CREDENTIALS_IMAGES.step1Ads} alt="step1" className="w-[100%]" />
      }
    ]
  },
  {
    id: 9,
    title: 'Ingreso',
    substeps: [
      {
        description: (
          <ol start="2" style={{listStyleType: 'decimal', listStylePosition: 'inside' }}>
            <li>Luego selecciona <span className="font-bold">Importar</span> y en el recuadro de abajo selecciona{' '}
            también <span className="font-bold">Propiedades Google Analytics 4</span>, luego{' '}
            <span className="font-bold">Web</span> y presiona el botón <span className="font-bold">Continuar{' '}
                (Imagen 2)</span>.</li>
          </ol>
        ),
        content: <img src={GA4_PIXEL_CREDENTIALS_IMAGES.step2Ads} alt="step2" className="w-[100%]" />
      }
    ]
  },
  {
    id: 10,
    title: 'Ingreso',
    substeps: [
      {
        description: (
          <ol start="3" style={{listStyleType: 'decimal', listStylePosition: 'inside' }}>
            <li>Selecciona el evento <span className="font-bold">&quot;purchase&quot;</span>, que esté ligado a tu página web.
            En acción de conversión suele salir como <span className="font-bold">&#123;nombre página&#125; &#123;web&#125; purchase</span>.
            {' '}Luego presiona importar y continuar <span className="font-bold">(Imagen 3)</span>.</li>
          </ol>
        ),
        content: <img src={GA4_PIXEL_CREDENTIALS_IMAGES.step3Ads} alt="step3" className="w-[100%]" />
      }
    ]
  },
  {
    id: 11,
    title: 'Conexión',
    substeps: [
      {
        description: (
          <ol start="4" style={{listStyleType: 'decimal', listStylePosition: 'inside' }}>
            <li>Debes pasar esta acción de conversión como <span className="font-bold">evento principal</span>. Para hacer{' '}
            esto presiona la acción recientemente añadida, ve a <span className="font-bold">Objetivos - Resumen</span>{' '}
            y luego en <span className="font-bold">Configuración</span>, en <span className="font-bold">Optimización de acciones</span> se selecciona{' '}
            <span className="font-bold">&quot;Acción principal para optimizar las ofertas&quot;</span>{' '} y luego guardar{' '}
            <span className="font-bold">(Imagen 4)</span>.
            </li>
          </ol>
        ),
        content: <img src={GA4_PIXEL_CREDENTIALS_IMAGES.step4Ads} alt="step4" className="w-[100%]" />
      }
    ]
  },
  {
    id: 12,
    title: '¡La conexión entre las plataformas se ha realizado con éxito!',
    substeps: [
      {
        description: (
          <div className="mb-[35vh]">
            Has completado todos los pasos del proceso de integración con Google Analytics y Ads.
            <br />
            <br />
            Ahora puedes empezar a aprovechar los datos recopilados de manera eficiente para optimizar{' '}
            tus campañas y tomar decisiones informadas.
          </div>
        )
      }
    ]
  },
  {
    id: 13,
    title: 'Ingreso',
    substeps: [
      {
        description: (
          <ol start="4" style={{listStyleType: 'decimal', listStylePosition: 'inside' }}>
            <li>En Detalles del flujo web, <span className="font-bold">copia el ID de medición de{' '}
                los detalles del flujo (Imagen 4)</span>.
            </li>
          </ol>
        ),
        content: <img src={GA4_PIXEL_CREDENTIALS_IMAGES.step4} alt="step4" className="w-[100%]" />
      },
      {
        description: (
          <ol start="5" style={{listStyleType: 'decimal', listStylePosition: 'inside' }}>
            <li>Ahora copia el ID de medición (ID de medición de los detalles del flujo) que deberás{' '}
              ingresar en el recuadro correspondiente en Configuraciones.
            </li>
          </ol>
        )
      }
    ]
  },
  {
    id: 14,
    title: 'Conexión',
    substeps: [
      {
        description: (
          <ol start="7" style={{listStyleType: 'decimal', listStylePosition: 'inside' }}>
            <li>Crea un valor secreto ingresando el nombre deseado y{' '}
                <span className="font-bold">copia el valor generado (Imagen 6)</span>.
            </li>
          </ol>
        ),
        content: <img src={GA4_PIXEL_CREDENTIALS_IMAGES.step6} alt="step6" className="w-[100%]" />
      },
      {
        description: (
          <ol start="8" style={{listStyleType: 'decimal', listStylePosition: 'inside' }}>
            <li>Copia el valor secreto asignado para luego ingresarlo en el recuadro{' '}
              correspondiente en Configuraciones.
            </li>
          </ol>
        )
      }
    ]
  }
]

import React from 'react'

const CheckBox = ({ checked, handleChange, text, className = '' }) => (
  <div
    className={`flex m-2 p-1 hover:bg-light-grey text-sm text-ultra-dark-grey ${className}`}
    onClick={handleChange}
    tabIndex="0"
    role="button"
  >
    <div
      className={`mx-2 w-3 h-3 my-auto border rounded border-dark-grey ${
        checked && 'bg-normal-pinflag border-normal-grey'
      }`}
    />
    {text}
  </div>
)

export default CheckBox

import { COLORS } from './styles'

export const PACKAGE_WITH_ERROR = 'with_error'
export const PACKAGE_BOUGHT = 'bought'
export const PACKAGE_PROCESSED = 'processed'
export const PACKAGE_IN_TRANSIT = 'in_transit'
export const PACKAGE_ON_DELIVERY = 'on_delivery'
export const PACKAGE_RECEIVED = 'received'
export const PACKAGE_STORED = 'stored'
export const PACKAGE_PICKED_UP = 'picked_up'
export const PACKAGE_DELIVERED = 'delivered'
export const PACKAGE_PARTIAL_DELIVERY = 'partial_delivery'
export const PACKAGE_ON_RETURN = 'on_return'
export const PACKAGE_RETURNED = 'returned'
export const PACKAGE_TOTAL_INCIDENT = 'total_incident'
export const PACKAGE_CANCELLED = 'cancelled'
export const ALL_RECEIVED_STATUS = `${PACKAGE_PICKED_UP},${PACKAGE_STORED},${PACKAGE_RECEIVED}`

export const PACKAGE_STATUSES = [
  PACKAGE_BOUGHT,
  PACKAGE_PROCESSED,
  PACKAGE_IN_TRANSIT,
  PACKAGE_ON_DELIVERY,
  PACKAGE_RECEIVED,
  PACKAGE_STORED,
  PACKAGE_PICKED_UP,
  PACKAGE_DELIVERED,
  PACKAGE_PARTIAL_DELIVERY,
  PACKAGE_ON_RETURN,
  PACKAGE_RETURNED,
  PACKAGE_TOTAL_INCIDENT,
  PACKAGE_CANCELLED
]

export const PACKAGE_STATUS_TRANSLATE = {
  [PACKAGE_BOUGHT]: 'Comprado',
  [PACKAGE_PROCESSED]: 'Procesado',
  [PACKAGE_IN_TRANSIT]: 'En camino',
  [PACKAGE_ON_DELIVERY]: 'En reparto',
  [PACKAGE_RECEIVED]: 'Recepcionado',
  [PACKAGE_STORED]: 'Recepcionado',
  [PACKAGE_PICKED_UP]: 'Recepcionado',
  [PACKAGE_DELIVERED]: 'Entregado',
  [PACKAGE_PARTIAL_DELIVERY]: 'Entrega parcial',
  [PACKAGE_ON_RETURN]: 'En ruta bodega',
  [PACKAGE_RETURNED]: 'Devuelto bodega',
  [PACKAGE_TOTAL_INCIDENT]: 'Incidencia total',
  [PACKAGE_CANCELLED]: 'Cancelado'
}

export const PACKAGE_STATUS_TRANSLATE_DISTINCT = {
  [PACKAGE_BOUGHT]: 'Comprado',
  [PACKAGE_PROCESSED]: 'Procesado',
  [PACKAGE_IN_TRANSIT]: 'En camino',
  [PACKAGE_ON_DELIVERY]: 'En reparto',
  [PACKAGE_RECEIVED]: 'Recepcionado',
  [PACKAGE_STORED]: 'Guardado',
  [PACKAGE_PICKED_UP]: 'Pickeado',
  [PACKAGE_DELIVERED]: 'Entregado',
  [PACKAGE_PARTIAL_DELIVERY]: 'Entrega parcial',
  [PACKAGE_ON_RETURN]: 'En ruta bodega',
  [PACKAGE_RETURNED]: 'Devuelto bodega',
  [PACKAGE_TOTAL_INCIDENT]: 'Incidencia total',
  [PACKAGE_CANCELLED]: 'Cancelado'
}

export const TO_CLIENT_STATUS = {
  [PACKAGE_BOUGHT]: PACKAGE_BOUGHT,
  [PACKAGE_PROCESSED]: PACKAGE_PROCESSED,
  [PACKAGE_IN_TRANSIT]: PACKAGE_IN_TRANSIT,
  [PACKAGE_ON_DELIVERY]: PACKAGE_ON_DELIVERY,
  [PACKAGE_RECEIVED]: PACKAGE_RECEIVED,
  [PACKAGE_STORED]: PACKAGE_RECEIVED,
  [PACKAGE_PICKED_UP]: PACKAGE_RECEIVED,
  [PACKAGE_DELIVERED]: PACKAGE_DELIVERED,
  [PACKAGE_PARTIAL_DELIVERY]: PACKAGE_DELIVERED,
  [PACKAGE_ON_RETURN]: PACKAGE_ON_RETURN,
  [PACKAGE_RETURNED]: PACKAGE_RETURNED,
  [PACKAGE_TOTAL_INCIDENT]: PACKAGE_TOTAL_INCIDENT
}

export const PACKAGE_STATUS_COLORS = {
  [PACKAGE_BOUGHT]: {
    backgroundColor: COLORS.STATUS_BACKGROUND_BLUE,
    textColor: COLORS.STATUS_TEXT_BLUE
  },
  [PACKAGE_PROCESSED]: {
    backgroundColor: COLORS.STATUS_BACKGROUND_BLUE,
    textColor: COLORS.STATUS_TEXT_BLUE
  },
  [PACKAGE_IN_TRANSIT]: {
    backgroundColor: COLORS.STATUS_BACKGROUND_LIGHT_GREEN,
    textColor: COLORS.STATUS_TEXT_LIGHT_GREEN
  },
  [PACKAGE_ON_DELIVERY]: {
    backgroundColor: COLORS.STATUS_BACKGROUND_LIGHT_GREEN,
    textColor: COLORS.STATUS_TEXT_LIGHT_GREEN
  },
  [PACKAGE_RECEIVED]: {
    backgroundColor: COLORS.STATUS_BACKGROUND_LIGHT_GREEN,
    textColor: COLORS.STATUS_TEXT_LIGHT_GREEN
  },
  [PACKAGE_STORED]: {
    backgroundColor: COLORS.STATUS_BACKGROUND_LIGHT_GREEN,
    textColor: COLORS.STATUS_TEXT_LIGHT_GREEN
  },
  [PACKAGE_PICKED_UP]: {
    backgroundColor: COLORS.STATUS_BACKGROUND_LIGHT_GREEN,
    textColor: COLORS.STATUS_TEXT_LIGHT_GREEN
  },
  [PACKAGE_DELIVERED]: {
    backgroundColor: COLORS.DARK_GREEN,
    textColor: COLORS.LIGHT_GREEN
  },
  [PACKAGE_PARTIAL_DELIVERY]: {
    backgroundColor: COLORS.DARK_GREEN,
    textColor: COLORS.LIGHT_GREEN
  },
  [PACKAGE_ON_RETURN]: {
    backgroundColor: COLORS.RED,
    textColor: COLORS.WHITE
  },
  [PACKAGE_RETURNED]: {
    backgroundColor: COLORS.RED,
    textColor: COLORS.WHITE
  },
  [PACKAGE_TOTAL_INCIDENT]: {
    backgroundColor: COLORS.RED,
    textColor: COLORS.WHITE
  },
  [PACKAGE_CANCELLED]: {
    backgroundColor: COLORS.RED,
    textColor: COLORS.WHITE
  }
}

import React, { useCallback, useContext, useState } from 'react'

import { Table, TableRow } from '../components/shared/Table/Table'
import SectionHeader from '../components/shared/SectionHeader'
import useFetch from '../hooks/useFetchParams'
import { getIncidents } from '../helpers/request/incidents'
import { getFormattedDate } from '../utils/dates'
import { searchTermsGenerator } from '../utils/filters'
import Button from '../components/shared/Button'
import Pagination from '../components/shared/Pagination/Pagination'
import DetailModal from '../components/Incidents/DetailModal'
import SearchBar from '../components/shared/SearchBar'
import { isSubstring } from '../utils/strings'
import { identifierCreator } from '../helpers/sales'
import ClipBoardText from '../components/shared/ClipboardText'
import ActionsBar from '../components/shared/Table/ActionsBar'
import IncidentsFilters from '../components/Incidents/IncidentsFilters'
import { AuthContext } from '../contexts/Store'
import NoAccess from '../components/NoAccess'
import { sortArrayByKey } from '../utils/arrays'

const Incidents = () => {
  const [incidents, setIncidents] = useState([])
  const [searchBarInput, setSearchBarInput] = useState('')
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [selectedIncident, setSelectedIncident] = useState({})
  const [searchResult, setSearchResult] = useState([])
  const [filteredIncidents, setFilteredIncidents] = useState([])
  const [isFiltered, setIsFiltered] = useState(false)

  const { hasAccess, loadingResources } = useContext(AuthContext)

  const setIncidentsData = useCallback((data) => {
    const sortedIncidents = data.length > 0
    ? sortArrayByKey(data,  { key: 'createdAt', type: 'date' } ,'desc')
    : data
    setIncidents(sortedIncidents)
    setSearchResult(sortedIncidents)
  }, [])

  const { isLoading, error } = useFetch(getIncidents, setIncidentsData, false)

  const searchBarFilter = (incidentsList, searchTerm) => {
    setSearchBarInput(searchTerm)
    if (searchTerm !== '') {
      const searchTerms = searchTermsGenerator(searchTerm)
      const searchedIncidents = incidentsList.filter(
        (incident) => searchTerms.some((term) => (
          isSubstring(incident.orderId, term) ||
          isSubstring(incident.statusDescription, term) ||
          isSubstring(incident.trackingId, term) ||
          isSubstring(incident.customerName, term)
        ))
      )
      setSearchResult(searchedIncidents)
    } else {
      setSearchResult(incidentsList)
    }
  }

  const searchHandler = (searchTerm) => {
    setSearchBarInput(searchTerm)
    const incidentsList = isFiltered ? filteredIncidents : incidents
    searchBarFilter(incidentsList, searchTerm)
  }

  const handleFilterChange = (incidentsList) => {
    setFilteredIncidents(incidentsList)
    searchBarFilter(incidentsList, searchBarInput)
  }

  const currentIncidents = searchResult.slice(pageSize * (page - 1), pageSize * page)

  if (!hasAccess('incidents')) {
    return (
      <div className="h-screen bg-light-grey">
        <NoAccess />
      </div>
    )
  }


  const handleSort = (sortValues, order) => {
    const sortedCurrentIncidents = sortArrayByKey(searchResult, sortValues, order)

    setSearchResult(sortedCurrentIncidents)
    const sortedIncidents = sortArrayByKey(incidents, sortValues, order)

    setIncidents(sortedIncidents)
  }
  const sortValues = { Creación: { key: 'createdAt', type: 'date' } }

  return (
    <div className="h-screen flex flex-col bg-light-grey">
      <SectionHeader
        title="Incidencias"
        searchBar={
          <SearchBar
            searchbarInput={searchBarInput}
            searchKeyword={searchHandler}
            setSearchbarInput={(search) => {
              setSearchBarInput(search)
            }}
          />
        }
      />
      <ActionsBar
        rightChildren={
          <IncidentsFilters
            incidents={incidents}
            handleFilterChange={handleFilterChange}
            isFiltered={isFiltered}
            setIsFiltered={setIsFiltered}
          />
        }
      />
      {searchResult.length === 0 && !isLoading && !error ? (
        <div className="mx-10 border rounded-b text-center py-8 bg-white text-s">
          No hay incidencias actualmente.
        </div>
      ) : (
        <>
          <Table
            maxHeight="max-h-[70vh]"
            headers={['O. de venta', 'Incidente', 'Creación', 'Acción']}
            isLoading={isLoading || loadingResources}
            error={error}
            sortValues={sortValues}
            sortData={handleSort}
            minHeight
          >
            {currentIncidents.map((incident) => (
              <TableRow
                key={incident.packageId}
                items={[
                  <div>
                    {identifierCreator(incident.companyName)}-
                    <ClipBoardText text={incident.orderId} />
                  </div>,
                  <div className="font-medium text-ultra-dark-grey">
                    {incident.statusDescription}
                  </div>,
                  getFormattedDate(incident.createdAt),
                  <Button
                    onClick={() => setSelectedIncident(incident)}
                    border="border"
                    textColor="text-medium-dark-grey"
                    small
                  >
                    Solucionar
                  </Button>
                ]}
              />
            ))}
          </Table>
          <div className="h-16 flex place-content-end mx-10">
            <Pagination
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              itemsCount={searchResult.length}
            />
          </div>
          <DetailModal incident={selectedIncident} handleClose={() => setSelectedIncident({})} />
        </>
      )}
    </div>
  )
}

export default Incidents

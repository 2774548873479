import React from 'react'
import { greenCheck, iconCircle } from '../../../constants/icons'

const UpperStepper = ({ steps, step, isLowerStepper }) => {

  const renderStepIcon = (index) => {
    if (index < step) {
      if (!isLowerStepper){
        return (
          <img
            src={greenCheck}
            alt="Step completed"
            className="w-10 h-10"
          />
        )
      }
      return (
        <img
          src={iconCircle}
          alt="Step completed"
          className="w-8 h-8"
        />
      )
    } if (index === step) {
      if (!isLowerStepper){
        return (
          <div className="w-8 h-8 border border-normal-pinflag rounded-full flex justify-center items-center">
            <div className="w-2 h-2 bg-normal-pinflag rounded-full" />
          </div>
        )
      }
      return (
        <div className="w-8 h-8 border border-normal-pinflag bg-normal-pinflag  rounded-full flex justify-center items-center">
          <span className="text-white text-sm font-medium">{index + 1}</span>
        </div>
      )
    }
    return (
      <div className="w-8 h-8 border border-normal-pinflag rounded-full flex justify-center items-center" />
    )
  }

  return (
    <div className="flex items-center text-sm w-full flex-row">
      {steps.map((currentStep, index) => (
        <div className="flex items-center text-sm w-full flex-col">
          <div className="relative flex-1 flex flex-col w-full" key={currentStep}>
            <div className="flex items-center w-full" key={currentStep}>
              {index < steps.length && (
                <div
                className={`flex-grow h-1 ${
                    index === 0 ? 'bg-white' : 'bg-normal-pinflag'
                }`}
                />
              )}
              <div className="relative flex flex-col items-center">
                <div className="relative z-10 flex items-center justify-center w-10 h-10 rounded-full">
                {renderStepIcon(index)}
                </div>
              </div>
              {index < steps.length && (
                <div
                className={`flex-grow h-1 ${
                    index === steps.length - 1 ? 'bg-white' : 'bg-normal-pinflag'
                }`}
                />
              )}
            </div>
          </div>
          <div className="mt-2 text-center">{currentStep}</div>
        </div>
      ))}
    </div>
  )
}

export default UpperStepper

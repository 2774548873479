import React from 'react'

import Button from '../components/shared/Button'
import ToggleSwitch from '../components/shared/ToggleSwitch/ToggleSwitch.style'

import { ASSETS_URL } from './others'

export const CONFIGURATION_STEPS = ['Integración', 'Inicia Sesión', 'Permisos', 'Conexión']

export const MULTIVENDE_IMAGES = {
  logo: `${ASSETS_URL}/multivende/multivende-logo.png`,
  logoNoText: `${ASSETS_URL}/multivende/logo-multivende-no-text.svg`,
  step1: `${ASSETS_URL}/multivende/step1.png`,
  step2: `${ASSETS_URL}/multivende/step2.png`,
  step3: `${ASSETS_URL}/multivende/step3.png`,
  successful: `${ASSETS_URL}/multivende/successful.png`,
  error: `${ASSETS_URL}/multivende/error.svg`
}

export const MULTIVENDE_STEPS = [
  {
    logo: <img src={MULTIVENDE_IMAGES.logo} alt="multivende" className="w-48" />,
    title: 'Integración a Multivende',
    description: (
      <>
        ¡Te damos la bienvenida a la integración de Multivende! A continuación, te guiaremos a
        través de los pasos para que puedas aprovechar al máximo esta integración.
        <br />
        <br />
        Para comenzar debes ingresar a la página de Multivende.
      </>
    ),
    content: (
      <Button
        color="bg-normal-pinflag"
        onClick={() =>
          window.open(
            'https://app.multivende.com/apps/authorize?response_type=code&client_id=449696536496&redirect_uri=asd&scope=read:checkouts'
          )
        }
      >
        Ir a Multivende
      </Button>
    )
  },
  {
    title: 'Inicia Sesión',
    description: 'Ingresa tus credenciales para entrar a la plataforma de Multivende.',
    content: <img src={MULTIVENDE_IMAGES.step1} alt="step1" className="w-[40%]" />
  },
  {
    title: 'Acepta los permisos',
    description: (
      <div className="flex">
        Se abrirá una ventana, es importante que verifiques que todos los permisos estén activos (
        <div className="mt-1">
          <ToggleSwitch size="ultra-small" check />
        </div>
        )
      </div>
    ),
    content: <img src={MULTIVENDE_IMAGES.step2} alt="step2" className="w-[40%]" />
  },
  {
    title: 'Nombra la conexión',
    description: (
      <>
        Una vez que hayas activado todos los permisos, nombra la conexión como prefieras y haz clic
        en <span className="font-medium">SI</span> y luego{' '}
        <span className="font-medium">guardar</span> los cambios.
      </>
    ),
    content: <img src={MULTIVENDE_IMAGES.step3} alt="step3" className="w-full" />
  }
]

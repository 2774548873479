import React from 'react'

import { greenCheck } from '../../../constants/icons'
import Button from '../../shared/Button'
import Modal from '../../shared/Modal/Modal'

import { SUCCESS_SAVE_CREDENTIALS_TITLE, SUCCESS_SAVE_CREDENTIALS_SUBTITLE, SUCCESS_TOGGLE_CREDENTIALS_TITLE } from '../../../constants/integrationWelcome'

const SuccessSaveCredentialsModal = ({ show, handleClose, isFirstTime, isActiveChange }) => (
  <Modal show={show} handleClose={handleClose}>
  <div className="flex flex-col justify-center items-center my-4 max-w-md text-center space-y-4">
    <img alt="icon" src={greenCheck} className="mx-auto w-14 h-auto"/>
    {isActiveChange ?
      SUCCESS_TOGGLE_CREDENTIALS_TITLE
    :
      SUCCESS_SAVE_CREDENTIALS_TITLE
    }
    {isFirstTime && (
      SUCCESS_SAVE_CREDENTIALS_SUBTITLE
    )}
  </div>
  <div className="flex justify-center gap-2 my-8">
    <Button onClick={handleClose} color="bg-normal-pinflag" border="border border-normal-pinflag">
    Continuar
    </Button>
  </div>
</Modal>
)

export default SuccessSaveCredentialsModal

import React from 'react'

import { formatPrice } from '../../../utils/numbers'

const Products = ({
  products,
  refundProducts,
  totalProductsAmount,
  saleDeliveryCost,
  paidDeliveryCost,
  totalProductsCost
}) => {
  // Calculate the total amount for refunded products
  const refundedProductsTotal = refundProducts.reduce(
    (total, refundProduct) => total + refundProduct.price * refundProduct.quantity,
    0
  )

  return (
    <div>
      {products.map((product) => {
        const refundProduct = refundProducts.find(
          (refundProductItem) => refundProductItem.sku === product.sku
        )
        return (
          <div
            className={`my-4 border rounded p-2 flex gap-3 text-s ${
              refundProduct ? 'border-normal-pinflag' : ''
            }`}
          >
            {product.imageUrls?.length > 0 ? (
              <img
                src={product.imageUrls[0]}
                alt={product.productName}
                className="max-w-[6rem] max-h-[6rem] w-auto h-auto"
              />
            ) : (
              <div className="w-24 h-24 bg-semi-light-grey" />
            )}
            <div>
              <div>{product.name}</div>
              <div className="text-dark-grey">SKU: {product.sku}</div>
              <div className="text-dark-grey">Cantidad: {product.quantity}</div>
              <div className="text-dark-grey">Precio: {formatPrice(product.price)}</div>
              {refundProduct && (
                <div className="text-xs text-normal-pinflag">
                  Cantidad devuelta: {refundProduct.quantity}
                </div>
              )}
            </div>
          </div>
        )
      })}
      <div className="flex justify-between mt-4 text-xs">
        <div className="text-medium-dark-grey">Cantidad total de productos</div>
        <div className="text-medium-dark-grey">{totalProductsAmount}</div>
      </div>
      <hr className="my-3" />
      <div className="flex justify-between mt-2 text-xs">
        <div className="text-medium-dark-grey">Envío</div>
        <div className="text-medium-dark-grey">{saleDeliveryCost}</div>
      </div>
      <div className="flex justify-between mt-2 text-xs">
        <div className="text-medium-dark-grey">Envío pagado por cliente</div>
        <div className="text-medium-dark-grey">{paidDeliveryCost}</div>
      </div>
      <div className="flex justify-between mt-2 text-xs">
        <div className="text-medium-dark-grey">Compra</div>
        <div className="text-medium-dark-grey">{formatPrice(totalProductsCost)}</div>
      </div>
      <hr className="my-3" />
      <div className="flex justify-between mt-2 text-xs">
        <div className="text-medium-dark-grey"><strong>Total</strong></div>
        <div className="text-medium-dark-grey">
          {totalProductsCost == null && paidDeliveryCost == null
            ? null
            : formatPrice((totalProductsCost ?? 0) + (paidDeliveryCost ?? 0))}
        </div>
      </div>
      <hr className="my-3" />
      <div className="flex justify-between mt-2 text-xs">
        <div className="text-medium-dark-grey">Total solicitado para devolucion</div>
        <div className="text-medium-dark-grey">
          {formatPrice(refundedProductsTotal)}
        </div>
      </div>
    </div>
  )
}

export default Products

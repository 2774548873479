import React, { useState } from 'react'

import Filters from '../../shared/Filters/Filters'
import { getCompaniesListNames } from '../../../helpers/companies'
import {
  activeChecks,
  filterArray,
  handleChecked
} from '../../../utils/filters'
import { STORE_ACTIVE, STORE_FULL, STORE_INACTIVE } from '../../../constants/stores'

const StoresFilters = ({ stores, handleFilterChange, isFiltered, setIsFiltered }) => {
  const companies = getCompaniesListNames()
  const [checkCompanies, setCheckCompanies] = useState(new Array(companies.length).fill(false))
  const statuses = [STORE_ACTIVE, STORE_INACTIVE, STORE_FULL]
  const [checkStatuses, setCheckStatuses] = useState(new Array(statuses.length).fill(false))

  const handleCompanies = (position) => {
    handleChecked(position, checkCompanies, setCheckCompanies)
  }

  const handleStatuses = (position) => {
    handleChecked(position, checkStatuses, setCheckStatuses)
  }

  const fields = [
    {
      name: 'Empresa',
      checkList: companies,
      handleCheck: handleCompanies,
      checkState: checkCompanies
    },
    {
      name: 'Estado',
      checkList: ['Activa', 'Inactiva', 'Llena'],
      handleCheck: handleStatuses,
      checkState: checkStatuses
    }
  ]

  const handleSubmit = () => {
    let filteredData = stores
    filteredData = filterArray(filteredData, checkCompanies, companies, 'companyName')

    filteredData = filterArray(filteredData, checkStatuses, statuses, 'status')

    handleFilterChange(filteredData)
    setIsFiltered(activeChecks(checkCompanies))
  }

  const handleClean = () => {
    setCheckCompanies(new Array(companies.length).fill(false))
    setIsFiltered(false)
    handleFilterChange(stores)
  }

  return (
    <Filters
      fields={fields}
      handleSubmit={handleSubmit}
      handleClean={handleClean}
      isFiltered={isFiltered}
      allowSingle={1}
      atLeft={1}
      minWidth="w-40"
    />
  )
}

export default StoresFilters

import React from 'react'

import { iconCircle } from '../../../constants/icons'

const CredentialValueWindow = ({ inputValues, handleInputChange, idInput,
  errorAccessToken, title, subtitle, areValuesVerified
}) => (
    <div className='mb-12'>
      <div className="">{title}</div>
      <div className='flex flex-row justify-between'>
        <div className="text-sm italic text-gray-500 my-1 mb-6 ">{subtitle}</div>
        {areValuesVerified && (
          <div className="flex flex-row">
            <img
              alt="back-arrow"
              src={iconCircle}
              className="w-4 h-4 mr-2 mt-1.5"
            />
            <div className="text-sm text-green my-1 mb-6 ">Verificado</div>
          </div>
        )}
      </div>
      <div className="relative w-full">
        <input
          value={inputValues[idInput]}
          onChange={(e) =>
            handleInputChange(
              idInput,
              e.target.value
            )
          }
          placeholder={inputValues[idInput]}
          className={`border p-2 rounded-xl w-full pr-10 bg-gray-100 ${
            errorAccessToken ? 'border-red' : 'border-gray-100'
          }`}
        />
        {errorAccessToken && (
          <div className="absolute inset-y-0 right-2 flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="w-5 h-5"
            >
              <circle
                cx="12"
                cy="12"
                r="11"
                fill="white"
                stroke="red"
                strokeWidth="2"
              />
              <line
                x1="12"
                y1="7"
                x2="12"
                y2="14"
                stroke="red"
                strokeWidth="2"
              />
              <circle cx="12" cy="17" r="1" fill="red" />
            </svg>
          </div>
        )}
      </div>
      {errorAccessToken && <div className="text-red text-sm mt-2">{errorAccessToken}</div>}
    </div>
  )

export default CredentialValueWindow

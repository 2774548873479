import React, { useState, useEffect } from 'react'
import EditBox from '../Personalization/EditBox'
import ToggleSwitch from '../../shared/ToggleSwitch/ToggleSwitch.style'
import { COLORS } from '../../../constants/styles'
import Button from '../../shared/Button'
import ProviderCredentialsModal from '../ProviderCredentialsModal'
import { editIcon3, LOGOS, passkeyIcon } from '../../../constants/icons'

const PinflagGateway = ({
  active,
  handleToggleActive,
  personalization,
  configuration,
  onUpdate,
  changes,
  setChanges
}) => {
  const [debitCardProvider, setDebitCardProvider] = useState('')
  const [creditCardProvider, setCreditCardProvider] = useState('')
  const [transferProvider, setTransferProvider] = useState('')

  useEffect(() => {
    const payment = personalization.payment || {}
    if (payment) {
      setDebitCardProvider(payment.debit === null ? 'none' : (payment.debit || ''))
      setCreditCardProvider(payment.credit === null ? 'none' : (payment.credit || ''))
      setTransferProvider(payment.transfer === null ? 'none' : (payment.transfer || ''))
    }
  }, [personalization])

  const [openCredentialsModal, setOpenCredentialsModal] = useState(false)
  const [selectedProvider, setSelectedProvider] = useState(null)

  const providerOptions = ['getnet', 'klap', 'webpay']

  const paymentOptions = [
    { value: '', label: 'Seleccionar' },
    { value: 'getnet', label: 'Getnet' },
    { value: 'klap', label: 'Klap' },
    { value: 'webpay', label: 'Webpay' },
    { value: 'none', label: 'Ninguno' }
  ]

  const isOptionAProvider = (option) => providerOptions.includes(option)

  const hasProviderCredentials = (provider) => {
    if (!provider) return false

    const credentials = configuration.paymentProviders?.[`${provider}Credentials`]
    return credentials !== null && credentials !== undefined
  }

  const getButtonConfig = (provider) => {
    const hasCredentials = hasProviderCredentials(provider)

    return {
      text: hasCredentials ? 'Editar credenciales' : 'Ingresar credenciales',
      icon: hasCredentials ? editIcon3 : passkeyIcon,
      backgroundColor: hasCredentials ? COLORS.GREEN : null
    }
  }

  const handleOpenCredentialsModal = (paymentProvider) => {
    setSelectedProvider(paymentProvider)
    setOpenCredentialsModal(true)
  }

  const handleCredentialsUpdate = (updates) => {
    const credentialsChanges = {
      ...changes,
      configuration: {
        ...configuration,
        ...changes.configuration,
        paymentProviders: {
          ...configuration.paymentProviders,
          ...changes.configuration?.paymentProviders,
          ...updates.configuration?.paymentProviders
        }
      }
    }
    setChanges(credentialsChanges)
    onUpdate(credentialsChanges)
  }

  return (
    <EditBox
      title={
        <div className="flex items-center gap-2">
          <img src={LOGOS.flag} alt="pinflag" className="h-6 w-6" />
          <span>Pinflag</span>
        </div>
      }
      description="Elige qué empresa gestionará los pagos"
      rightItem={
        <ToggleSwitch
          size="ultra-small"
          inactiveColor={COLORS.LIGHT_GREY}
          check={active}
          setCheck={handleToggleActive}
        />
      }
      className="p-4 text-gray-400"
    >
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <div className="text-sm text-gray-600">Tarjeta débito</div>
          <select
            value={debitCardProvider}
            onChange={(e) => {
              const stateValue = e.target.value
              const backendValue = stateValue === 'none' ? null : stateValue

              setDebitCardProvider(stateValue)
              onUpdate({
                ...changes,
                personalization: {
                  ...personalization,
                  ...changes.personalization,
                  payment: {
                    ...personalization.payment,
                    ...changes.personalization.payment,
                    debit: backendValue
                  }
                }
              })
            }}
            className="w-full border border-gray-300 rounded-md p-2 text-sm"
          >
            {paymentOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {(debitCardProvider && isOptionAProvider(debitCardProvider)) && (
            <Button
              submit
              color={getButtonConfig(debitCardProvider).backgroundColor === COLORS.GREEN ? 'bg-normal-pinflag' : 'bg-light-pinflag'}
              textColor={getButtonConfig(debitCardProvider).backgroundColor === COLORS.GREEN ? 'text-white' : 'text-normal-pinflag'}
              border={getButtonConfig(debitCardProvider).backgroundColor === COLORS.GREEN ? 'border border-green' : 'border border-normal-pinflag'}
              width="w-full"
              onClick={() => handleOpenCredentialsModal(debitCardProvider)}
              className="mt-2"
            >
              <div
                className="flex items-center justify-center gap-2"
              >
                <img
                  src={getButtonConfig(debitCardProvider).icon}
                  alt="pinflag"
                  className="h-5 w-5"
                />
                <div>{getButtonConfig(debitCardProvider).text}</div>
              </div>
            </Button>
          )}
        </div>

        <div className="flex flex-col gap-2">
          <div className="text-sm text-gray-600">Tarjeta crédito</div>
          <select
            value={creditCardProvider}
            onChange={(e) => {
              const stateValue = e.target.value
              const backendValue = stateValue === 'none' ? null : stateValue

              setCreditCardProvider(stateValue)
              onUpdate({
                ...changes,
                personalization: {
                  ...personalization,
                  ...changes.personalization,
                  payment: {
                    ...personalization.payment,
                    ...changes.personalization.payment,
                    credit: backendValue
                  }
                }
              })
            }}
            className="w-full border border-gray-300 rounded-md p-2 text-sm"
          >
            {paymentOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {(creditCardProvider && isOptionAProvider(creditCardProvider)) && (
            <Button
              submit
              color={getButtonConfig(creditCardProvider).backgroundColor === COLORS.GREEN ? 'bg-normal-pinflag' : 'bg-light-pinflag'}
              textColor={getButtonConfig(creditCardProvider).backgroundColor === COLORS.GREEN ? 'text-white' : 'text-normal-pinflag'}
              border={getButtonConfig(creditCardProvider).backgroundColor === COLORS.GREEN ? 'border border-green' : 'border border-normal-pinflag'}
              width="w-full"
              onClick={() => handleOpenCredentialsModal(creditCardProvider)}
              className="mt-2"
            >
              <div className="flex items-center justify-center gap-2">
                <img
                  src={getButtonConfig(creditCardProvider).icon}
                  alt="pinflag"
                  className="h-4 w-4"
                />
                <div>{getButtonConfig(creditCardProvider).text}</div>
              </div>
            </Button>
          )}
        </div>

        <div className="flex flex-col gap-2">
          <div className="text-sm text-gray-600">Transferencia</div>
          <select
            value={transferProvider}
            onChange={(e) => {
              const stateValue = e.target.value
              const backendValue = stateValue === 'none' ? null : stateValue

              setTransferProvider(stateValue)
              onUpdate({
                ...changes,
                personalization: {
                  ...personalization,
                  ...changes.personalization,
                  payment: {
                    ...personalization.payment,
                    ...changes.personalization.payment,
                    transfer: backendValue
                  }
                }
              })
            }}
            className="w-full border border-gray-300 rounded-md p-2 text-sm"
          >
            {paymentOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {(transferProvider && isOptionAProvider(transferProvider)) && (
            <Button
              submit
              color={getButtonConfig(transferProvider).backgroundColor === COLORS.GREEN ? 'bg-normal-pinflag' : 'bg-light-pinflag'}
              textColor={getButtonConfig(transferProvider).backgroundColor === COLORS.GREEN ? 'text-white' : 'text-normal-pinflag'}
              border={getButtonConfig(transferProvider).backgroundColor === COLORS.GREEN ? 'border border-green' : 'border border-normal-pinflag'}
              width="w-full"
              onClick={() => handleOpenCredentialsModal(transferProvider)}
              className="mt-2"
            >
              <div className="flex items-center justify-center gap-2">
                <img
                  src={getButtonConfig(transferProvider).icon}
                  alt="pinflag"
                  className="h-4 w-4"
                />
                <div>{getButtonConfig(transferProvider).text}</div>
              </div>
            </Button>
          )}
        </div>
      </div>

      {/* Modals */}
      {openCredentialsModal && (
        <ProviderCredentialsModal
          provider={selectedProvider}
          configuration={configuration}
          isOpen={openCredentialsModal}
          closeModal={() => {
            setOpenCredentialsModal(false)
            setSelectedProvider(null)
          }}
          onSuccess={() => setOpenCredentialsModal(false)}
          onUpdate={handleCredentialsUpdate}
        />
      )}
    </EditBox>
  )
}

export default PinflagGateway

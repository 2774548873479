import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import ErrorSaveCredentialsModal from '../../../../components/Settings/Integrations/ErrorSaveCredentialsModal'
import GA4PixelCredentialsStep from '../../../../components/Settings/Integrations/GA4PixelCredentialsStep'
import PixelStepper from '../../../../components/Settings/Integrations/PixelStepper'
import SuccessSaveCredentialsModal from '../../../../components/Settings/Integrations/SuccessSaveCredentialsModal'
import useSubmitPixelCredentials from '../../../../components/Settings/useSubmitPixelCredentials'
import Button from '../../../../components/shared/Button'
import RawLoader from '../../../../components/shared/Loader/RawLoader'
import SectionHeader from '../../../../components/shared/SectionHeader2'
import { GA4_PIXEL_CREDENTIALS_STEPS, CONFIGURATION_STEPS_UP, CONFIGURATION_STEPS_DOWN, GA4_PIXEL_NAME } from '../../../../constants/ga4PixelCredential'
import { PixelCredentialsContext } from '../../../../contexts/PixelCredentialsContext'
import ROUTES from '../../../../constants/routes'
import { parsePixelCredentials, getUpperStepGA4, validateInputGA4 } from '../../../../helpers/pixelCredentials'

const GA4PixelCredentials = () => {

  const { companyId,
    pixels,
    setPixelCredentials,
    setPixelIsActive,
    setPixelHasCredentialsSave
  } = useContext(PixelCredentialsContext)

  const { hasCredentialsSave } = pixels.ga4


  const [step, setStep] = useState(0)
  const [isLoadingStep, setIsLoadingStep] = useState(false)
  const [error, setError] = useState('')
  const [inputValues, setInputValues] = useState({
    pixelId: '',
    pixelAccessToken: ''
  })

  const navigate = useNavigate()

  const upperStepperSteps = CONFIGURATION_STEPS_UP.slice(0, 3)
  const lowerStepperStepsGA4 = CONFIGURATION_STEPS_DOWN.slice(0, 6)
  const lowerStepperStepsAds = CONFIGURATION_STEPS_DOWN.slice(0, 4)

  const { onSubmit, openResponseModal, setOpenResponseModal, loadingSubmit, errorSubmit } =
  useSubmitPixelCredentials()

  const handleInputChange = (id, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [id]: value
    }))
  }

  const validateInputValue = () => {
    const stepError = validateInputGA4(step, inputValues)
    setError(stepError)
    if (stepError === '') return true
    return false
  }

  const upperStep = getUpperStepGA4(step)

  const submitCredentials = async () => {
    const parseCredentials = parsePixelCredentials(companyId, inputValues, GA4_PIXEL_NAME, true)

    await onSubmit(parseCredentials,
      (newValues) => setPixelCredentials(GA4_PIXEL_NAME, newValues),
      (value) => setPixelHasCredentialsSave(GA4_PIXEL_NAME, value),
      (value) => setPixelIsActive(GA4_PIXEL_NAME, value),
      true)
  }

  const handleBack = () => {
    if (step === 0) {
      navigate(-1)
    } else {
      setStep(step - 1)
    }
  }

  const handleContinue = () => {
    if (hasCredentialsSave) {
      setStep(step + 1)
    }
    const isValidInput = validateInputValue()
    if (isValidInput) {
        if (step === 6) {
            submitCredentials()
        } else {
            setStep(step + 1)
        }
    }
  }

  const handleStepClick = (index) => {
    const stepMapping = {
      0: step < 7 ? 0 : 7,
      1: step < 7 ? 1 : 8,
      2: step < 7 ? 6 : 11
    }
    const nextStep = stepMapping[index]

    if (nextStep !== undefined) {
      setStep(nextStep)
    }
  }

  const goToIntegrations = () => {
    navigate(ROUTES.INTEGRATIONS)
  }

  const handleModalClose = () => {
    setOpenResponseModal(false)
    setStep(7)
  }

  const handleModalCloseError = () => {
    setOpenResponseModal(false)
  }

  useEffect(() => {
    setIsLoadingStep(true)
    const timeout = setTimeout(() => {
      setIsLoadingStep(false)
    }, 500)

    return () => clearTimeout(timeout)
  }, [step])

  return (
    <div className="flex flex-col h-screen">
      <SectionHeader title="Integraciones" />
      <div className="bg-general-background py-8 px-16 grow">
        <div className="bg-white p-3 rounded-lg mb-4 flex justify-center">
          <PixelStepper steps={upperStepperSteps} step={upperStep} setStep={handleStepClick}
          isLowerStepper={false}/>
        </div>

        {isLoadingStep ? (
          <div className="flex items-center justify-center h-full">
            <RawLoader />
          </div>
        ) : (

        <div className="flex flex-col justify-between bg-white p-12 rounded-lg h-max">
          <GA4PixelCredentialsStep
            step={
              (step === 4 || step === 6) && hasCredentialsSave
                ? GA4_PIXEL_CREDENTIALS_STEPS[step === 4 ? 13 : 14]
                : GA4_PIXEL_CREDENTIALS_STEPS[step]
            }
            inputValues={inputValues}
            handleInputChange={handleInputChange}
            error={error}
            hasCredentialsSave={hasCredentialsSave}
          />
          {(step >= 1 && step <= 6) && (
            <div className="bg-white p-3 rounded-lg mb-4 flex justify-center">
              <PixelStepper steps={lowerStepperStepsGA4} step={step - 1} setStep={setStep}
              isLowerStepper/>
            </div>
          )}
          {(step >= 8 && step <= 11) && (
            <div className="bg-white p-3 rounded-lg mb-4 flex justify-center">
              <PixelStepper steps={lowerStepperStepsAds} step={step - 8} setStep={setStep}
              isLowerStepper/>
            </div>
          )}
          <div className="flex justify-end space-x-4">
            <Button
              border="border border-normal-pinflag"
              textColor="text-normal-pinflag"
              onClick={handleBack}
            >
              &lt;&lt; Volver
            </Button>
            {step < 12 && (
              <Button
                border="border border-normal-pinflag"
                color="bg-normal-pinflag"
                onClick={() => handleContinue()}
              >
                Continuar &gt;&gt;
              </Button>
            )}
            {step === 12 && (
              <Button
                border="border border-normal-pinflag"
                color="bg-normal-pinflag"
                onClick={() => goToIntegrations()}
              >
                Finalizar &gt;&gt;
              </Button>
            )}
          </div>
        </div>
        )}
      </div>
      {loadingSubmit && (
        <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 bg-gray-900 z-50">
          <RawLoader />
        </div>
      )}
      {errorSubmit && (
        <ErrorSaveCredentialsModal show={openResponseModal} handleClose={handleModalCloseError}/>
      )}
      {!loadingSubmit && !errorSubmit && (
        <SuccessSaveCredentialsModal show={openResponseModal} handleClose={handleModalClose}
        isFirstTime/>
      )}
    </div>
  )
}

export default GA4PixelCredentials

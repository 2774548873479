import React, { createContext, useState, useMemo, useEffect, useCallback } from 'react'
import secureLocalStorage from 'react-secure-storage'

import { getPixelCredentials } from '../helpers/request/stats'
import { GA4_PIXEL_NAME } from '../constants/ga4PixelCredential'
import { META_PIXEL_NAME } from '../constants/metaPixelCredential'
import { hasPixelCredentials } from '../helpers/pixelCredentials'

export const PixelCredentialsContext = createContext()

const PixelCredentialsProvider = ({ children }) => {
  const [companyId, setCompanyId] = useState(secureLocalStorage.getItem('companiesIds'))
  const [isLoading, setIsLoading] = useState(true)

  const [pixels, setPixels] = useState({
    ga4: {
      credentials: { pixelId: '', pixelAccessToken: '' },
      hasCredentialsSave: false,
      isActive: false
    },
    facebook: {
      credentials: { pixelId: '', pixelAccessToken: '' },
      hasCredentialsSave: false,
      isActive: false
    }
  })

  const updatePixelField = useCallback((pixelName, updates) => {
    setPixels((prevState) => ({
      ...prevState,
      [pixelName]: {
        ...prevState[pixelName],
        ...updates
      }
    }))
  }, [])

  const setPixelCredentials = useCallback((pixelName, credentials) => {
    updatePixelField(pixelName, { credentials })
  }, [updatePixelField])

  const setPixelHasCredentialsSave = useCallback((pixelName, hasCredentials) => {
    updatePixelField(pixelName, { hasCredentialsSave: hasCredentials })
  }, [updatePixelField])

  const setPixelIsActive = useCallback((pixelName, isActive) => {
    updatePixelField(pixelName, { isActive })
  }, [updatePixelField])

  const initializePixel = useCallback(
    (data, pixelName) => {
      if (!data || !data[pixelName]) return
      setPixelCredentials(pixelName, data[pixelName])
      setPixelHasCredentialsSave(pixelName, hasPixelCredentials(data[pixelName]))
      setPixelIsActive(pixelName, data[pixelName]?.isActive || false)
    },
    [setPixelCredentials, setPixelHasCredentialsSave, setPixelIsActive]
  )

  const initializeAllPixels = useCallback(
    (data) => {
      initializePixel(data, GA4_PIXEL_NAME)
      initializePixel(data, META_PIXEL_NAME)
    },
    [initializePixel]
  )

  useEffect(() => {
    const fetchPixelCredentials = async () => {
      try {
        const data = await getPixelCredentials({ params: companyId })
        if (data) initializeAllPixels(data)
      } catch (error) {
        console.error('Error fetching pixel credentials:', error)
      } finally {
        setIsLoading(false)
      }
    }

    if (companyId) {
      fetchPixelCredentials()
    }
  }, [companyId, initializeAllPixels])

  const value = useMemo(
    () => ({
      companyId,
      setCompanyId,
      isLoading,
      pixels,
      setPixelCredentials,
      setPixelHasCredentialsSave,
      setPixelIsActive
    }),
    [companyId,
    isLoading,
    pixels,
    setPixelCredentials,
    setPixelHasCredentialsSave,
    setPixelIsActive]
  )

  return <PixelCredentialsContext.Provider value={value}>
    {children}
  </PixelCredentialsContext.Provider>
}

export default PixelCredentialsProvider

import React from 'react'

import { GA4_PIXEL_CREDENTIALS_IMAGES } from './ga4PixelCredential'
import { META_PIXEL_CREDENTIALS_IMAGES } from './metaPixelCredential'
import ROUTES from './routes'

export const TITLE = 'Centro de Integraciones'
export const SUBTITLE = 'En esta sección, podrás conectar y configurar las herramientas clave que potencian tu negocio. Ya sea que desees integrar plataformas de análisis para medir tus métricas o herramientas de publicidad para optimizar tus campañas, aquí encontrarás todo lo necesario para comenzar.'

export const SUCCESS_SAVE_CREDENTIALS_TITLE = <span className="font-medium">Se han guardado tus credenciales con éxito</span>
export const SUCCESS_TOGGLE_CREDENTIALS_TITLE = <span className="font-medium">Se han actualizado tus preferencias con éxito</span>
export const SUCCESS_SAVE_CREDENTIALS_SUBTITLE = <div>Continúa el proceso para garantizar{' '}
  la conexión entre plataformas</div>

export const ERROR_SAVE_CREDENTIALS_TITLE = <span className="font-medium">Hubo un problema al cargar las credenciales</span>
export const ERROR_SAVE_CREDENTIALS_SUBTITLE = <div>Por favor intentalo nuevamente, si el problema
  persiste, contáctanos.</div>

export const ACCESS_TOKEN_NAME = 'pixelAccessToken'
export const PIXEL_ID_NAME = 'pixelId'

export const PURCHASE_NAME = 'purchase'

export const INTEGRATIONS_TEXTS = {
  multivende_name: 'Multivende',
  multivende_text: 'Optimiza tu operación en todo el proceso de venta a través de Multivende.',
  ga4_name: 'Google Analytics y Ads',
  ga4_text: 'Potencia el rendimiento de tus campañas y tu estrategia de marketing.',
  ga4_pixel_name: 'ga4',
  meta_name: 'Meta (Facebook)',
  meta_pixel_name: 'facebook',
  meta_text: 'Integra datos de tus campañas y mejora la gestión de tus anuncios.'
}

export const CREDENTIALS_SAVE = {
  'Google Analytics y Ads': {
    name: INTEGRATIONS_TEXTS.ga4_name,
    pixel_name: INTEGRATIONS_TEXTS.ga4_pixel_name,
    logo: <img src={GA4_PIXEL_CREDENTIALS_IMAGES.analytics} alt="GA4 logo" className="w-full h-full object-contain" />,
    title: 'Google Analytics',
    subtitle: (
      <>
        Ya tienes una propiedad en tu <u>cuenta de Google Analytics</u>.
        <br />
        Puedes encontrar tu ID de medición y el valor secreto asignado:
      </>
    ),
    pixel_id_title: <span className="font-bold">ID de Medición</span>,
    pixel_id_subtitle: 'ID de medición de los detalles del flujo',
    access_token_title: <span className="font-bold">Valor secreto</span>,
    access_token_subtitle: 'Valor secreto asignado',
    final_text: '¿No recuerdas como obtener estos datos desde Google Analytics?',
    onboarding_link: ROUTES.GA4
  },
  'Meta (Facebook)': {
    name: INTEGRATIONS_TEXTS.meta_name,
    pixel_name: INTEGRATIONS_TEXTS.meta_pixel_name,
    logo: <img src={META_PIXEL_CREDENTIALS_IMAGES.meta} alt="Meta logo" className="w-full h-full object-contain"/>,
    title: 'Meta (Facebook)',
    subtitle: (
      <>
        Ya tienes una propiedad en tu <u>cuenta de Meta (Facebook)</u>.
        <br />
        Puedes encontrar tu ID de acceso (Token) y el ID de Datos:
      </>
    ),
    pixel_id_title: <span className="font-bold">Access Token</span>,
    pixel_id_subtitle: 'ID de acceso para la API (token)',
    access_token_title: <span className="font-bold">ID de Datos</span>,
    access_token_subtitle: 'Id de origen de datos',
    final_text: '¿No recuerdas como obtener estos datos desde Meta (Facebook)?',
    onboarding_link: ROUTES.META
  }
}
